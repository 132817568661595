<div class="card">
    <div class="card-header">
      {{'pages.contact.detail.assignTitle' | translate}}
    </div>
    <div class="card-body">
      
      <ng-container *ngIf="contact.organisationId !== null">
        <div class="form-group">
          {{'pages.contact.detail.assignedDescription' | translate: {name: contact.organisationName} }}
        </div>

        <div class="form-group">
          <button class="btn btn-primary" (click)="unlink()">{{'pages.contact.detail.assignUnlink' | translate}}</button>&nbsp;&nbsp;<button type='button' class="btn btn-danger" (click)="close()">{{'global.close' | translate}}</button>
        </div>
      </ng-container>

      <ng-container *ngIf="contact.organisationId === null">

        <form [formGroup]="form">
          <div class="form-group">
            <label>{{'fields.organisationName' | translate}}:</label>
            <input type="text" formControlName="name" class="form-control big" />
            <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['name'].errors | objToKeys">
                  {{ 'errors.name.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          
          <div class="form-group">
            <button class="btn btn-primary" (click)="search()">{{'pages.contact.detail.search' | translate}}</button>
          </div>

          <ng-container *ngIf="response !== undefined">
            {{ 'pages.contact.detail.assignResult' | translate: {total: response.total} }}<br />
            <br />
            <ng-container *ngIf="response.total == 0">
              {{ 'pages.contact.detail.assignNoResult' | translate }}
            </ng-container>

            <div *ngFor="let item of response.data">
              {{item.name}} <button class="btn btn-primary" (click)="link(item.id)">{{'pages.contact.detail.assignLink' | translate}}</button>
              <hr />
            </div>
          </ng-container>

          <div class="form-group">
            <button type='button' class="btn btn-danger" (click)="close()">{{'global.close' | translate}}</button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>