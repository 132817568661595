import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Response } from '../../../../models/Response';
import { MyOrganisation } from '../../models/MyOrganisation';
import { MyOrganisationHttpService } from '../../services/my-organisation-http.service'
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { Address } from 'src/app/features/organisation/models/address';


@Component({
  selector: 'app-my-organisation',
  templateUrl: './my-organisation.component.html',
  styleUrls: ['./my-organisation.component.scss']
})
export class MyOrganizationComponent implements OnInit {

  error: Response;
  success: boolean;

  form: FormGroup;
  organisation: MyOrganisation;

  constructor(private formBuilder: FormBuilder,
    public snackBar: Snackbar,
    private service: MyOrganisationHttpService) {
    this.form = this.formBuilder.group({
      organisationName: new FormControl('', Validators.required),
      telephone: new FormControl(''),
      email: new FormControl(''),
      vatNumber: new FormControl(''),
      chamberOfCommerce: new FormControl(''),
        
      street: new FormControl(''),
      houseNumber: new FormControl(''),
      postalCode: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
    });
    this.load();
   }

  ngOnInit() {
  }

  load() {
    this.service.get().subscribe((success) => {
      this.organisation = success.data;
      this.setForm();
    }, (error) => {

     });
  }

  setForm() {
    this.form.controls.organisationName.setValue(this.organisation.organisationName);
    this.form.controls.telephone.setValue(this.organisation.telephone);
    this.form.controls.email.setValue(this.organisation.email);
    this.form.controls.chamberOfCommerce.setValue(this.organisation.chamberOfCommerce);
    this.form.controls.vatNumber.setValue(this.organisation.vatNumber);

    this.form.controls.country.setValue(this.organisation.address.country);
    this.form.controls.houseNumber.setValue(this.organisation.address.houseNumber);
    this.form.controls.street.setValue(this.organisation.address.street);
    this.form.controls.postalCode.setValue(this.organisation.address.postalCode);
    this.form.controls.city.setValue(this.organisation.address.place);
    this.form.controls.state.setValue(this.organisation.address.state);
  }

  submit()
  {
    if (this.form.dirty && this.form.valid) {
      let organisation = new MyOrganisation();
      organisation.organisationName = this.form.controls.organisationName.value;
      organisation.telephone = this.form.controls.telephone.value;
      organisation.email = this.form.controls.email.value;
      organisation.chamberOfCommerce = this.form.controls.chamberOfCommerce.value;
      organisation.vatNumber = this.form.controls.vatNumber.value;

      organisation.address = new Address();
      organisation.address.country = this.form.controls.country.value;
      organisation.address.houseNumber = this.form.controls.houseNumber.value;
      organisation.address.street = this.form.controls.street.value;
      organisation.address.postalCode = this.form.controls.postalCode.value;
      organisation.address.place = this.form.controls.city.value;
      organisation.address.state = this.form.controls.state.value;

      this.service.create(organisation).subscribe((success) => {
        this.snackBar.openSuccess('pages.invoice.detail.saved');
      });
    }
  }
}
