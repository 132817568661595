import { Invoice } from "../models/invoice";

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { ListResponse } from 'src/app/models/list-response';

@Injectable({
  providedIn: 'root',
})
export class InvoiceHttpService {
  constructor(private httpClient: HttpClient) { }

  list(take: number, skip: number): Observable<ListResponse<Invoice>> {
    return this.httpClient.get<ListResponse<Invoice>>(`/api/v1/invoice?take=${take}&skip=${skip}`);
  }

  get(id: number): Observable<ObjectResponse<Invoice>> {
    return this.httpClient.get<ObjectResponse<Invoice>>(`/api/v1/invoice/${id}`);
  }


  getSample(id: number): Observable<Blob> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.httpClient.get<Blob>(`/api/v1/invoice/${id}/sample`,
      { headers: headers, responseType: 'blob' as 'json' });
  }

  put(id: number, invoice: Invoice): Observable<ObjectResponse<Invoice>> {
    return this.httpClient.patch<ObjectResponse<Invoice>>(`/api/v1/invoice/${id}`, invoice);
  }

  create(references: string, contactId: number, number: number): Observable<ObjectResponse<Invoice>> {
    const invoice = new Invoice();
    invoice.contactId = contactId;
    invoice.number = number;
    invoice.references = references;

    return this.httpClient.post<ObjectResponse<Invoice>>(`/api/v1/invoice`, invoice);
  }
}
