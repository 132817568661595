<div class="wrapper" *ngIf="authService.isAuthenticated()">
  <app-side-menu></app-side-menu>

  <div class="inner-page">
    <div class="top-bar container-fluid">
      <div class="row">
        <div class="col-xs-10" style="width: 100%">
          <div class="mobile-logo">
            <h1><i class="far fa-balance-scale-right"></i></h1>
          </div>
          <div class="float-right top-menu">
            <span class="profile" (click)="profileMenu.toggle()" [class.active]="profileMenu.showMenu">
              <i class="far fa-user"></i>
            </span>
          </div>
          <app-profile-menu #profileMenu></app-profile-menu>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="padding-bottom: 10px;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
