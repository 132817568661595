import { Component, OnInit, ViewChild } from '@angular/core';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from '../../models/contact';
import { ServiceHttpService } from '../../services/service-http.service';
import { ListResponse } from 'src/app/models/list-response';
import { CreateContactDialogComponent } from '../../dialogs/create-contact-dialog/create-contact-dialog.component';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  pageSize = 10;
  pageNum: number;

  @ViewChild('paging') paging;

  contacts: ListResponse < Contact > ;

  constructor(private serviceService: ServiceHttpService,
              public matDialog: MatDialog,
              private router: Router,
              public snackBar: Snackbar,
              private route: ActivatedRoute) {
                this.route.queryParams.subscribe(params => {
                    this.pageNum = +params.page || 0;
                });
              }

  ngOnInit() {
    this.load();
  }

  create() {
    this.matDialog.open(CreateContactDialogComponent, {
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.router.navigate([ 'contact', result.id ]);
      }
    });
  }


  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.serviceService.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.contacts = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
    }, (error) => {
      // We should error here
    });
  }

  load() {
    this.serviceService.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.contacts = success;
    }, (error) => {});
  }

}
