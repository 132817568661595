import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ResponseMessage } from 'src/app/models/Response';
import { Contact } from '../../models/contact';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceHttpService } from '../../services/service-http.service';
import { OrganisationHttpService } from 'src/app/features/organisation/services/organisation-http.service';
import { ListResponse } from 'src/app/models/list-response';
import { Organisation } from 'src/app/features/organisation/models/organisation';
import { Snackbar } from 'src/app/shared/helper/snackbar';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './assign-organisation-dialog.component.html',
  styleUrls: ['./assign-organisation-dialog.component.scss']
})
export class AssignOrganisationDialogComponent implements OnInit {

  genericErrors: ResponseMessage[];

  response: ListResponse<Organisation>;

  form = new FormGroup({
    'name': new FormControl('', [ Validators.required ])
  });

  constructor(public dialogRef: MatDialogRef < AssignOrganisationDialogComponent >,
              @Inject(MAT_DIALOG_DATA) public contact: Contact,
              private organisationService: OrganisationHttpService,
              public snackBar: Snackbar,
              private serviceService: ServiceHttpService) {}

  ngOnInit(): void {
  }

  unlink()
  {
    let contact = { ...this.contact };
    contact.organisationId = null;

    this.serviceService.put(this.contact.id, contact).subscribe((success) => {
      this.snackBar.openSuccess('pages.contact.detail.unlinked');
      this.dialogRef.close();
    });
  }

  search()
  {
    if(this.form.invalid)
    {
      return;
    }

    this.organisationService.listOrganisation(10, 0, this.form.controls['name'].value).subscribe((success) => {
      this.response = success;
    });
  }

  link(id: number) {
    let contact = { ...this.contact };
    contact.organisationId = id;

    this.serviceService.put(this.contact.id, contact).subscribe((success) => {
      this.snackBar.openSuccess('pages.contact.detail.linked');
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}
