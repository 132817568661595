import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './features/auth/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { ProfileComponent } from './features/my-profile/components/profile/profile.component';
import { LoggedinComponent } from './shared/components/loggedin/loggedin.component';
import { AuthAnonGuardService } from './shared/auth/auth-anon.service';
import { ResourceListComponent } from './features/resource/components/resource-list/resource-list.component';
import { ResourceDetailComponent } from './features/resource/components/resource-detail/resource-detail.component';
import { AuthAdminGuardService } from './shared/auth/auth-adminguard.service';
import { UnauthenticatedComponent } from './shared/components/unauthenticated/unauthenticated.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './features/auth/forgot-password/components/reset-password/reset-password.component';
import { RegisterComponent } from './features/auth/register/components/register/register.component';
import { EmployeeListComponent } from './features/employee/components/employeelist/employee-list.component';
import { OrganisationListComponent } from './features/organisation/components/organisation-list/organisation-list.component';
import { OrganisationDetailComponent } from './features/organisation/components/organisation-detail/organisation-detail.component';
import { ContactListComponent } from './features/contact/components/contact-list/contact-list.component';
import { ContactDetailComponent } from './features/contact/components/contact-detail/contact-detail.component';
import { InvoiceDetailComponent } from './features/invoice/components/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './features/invoice/components/invoice-list/invoice-list.component';
import { MyOrganizationComponent } from './features/my-organisation/components/my-organisation/my-organisation.component';

const routes: Routes = [
  {
    path: '',
    component: LoggedinComponent,
    canActivate: [ AuthGuardService ],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'resource',
        component: ResourceListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'resource/:id',
        component: ResourceDetailComponent,
        canActivate: [ AuthAdminGuardService ]
      },
      {
        path: 'employee',
        component: EmployeeListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'organisation',
        component: OrganisationListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'my-organisation',
        component: MyOrganizationComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'organisation/:id',
        component: OrganisationDetailComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'contact',
        component: ContactListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'contact/:id',
        component: ContactDetailComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'invoice',
        component: InvoiceListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'invoice/:id',
        component: InvoiceDetailComponent,
        canActivate: [ AuthGuardService ]
      }
    ]
  },
  {
    path: 'auth',
    component: UnauthenticatedComponent,
    canActivate: [ AuthAnonGuardService ],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [ AuthAnonGuardService ]
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [ AuthAnonGuardService ]
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [ AuthAnonGuardService ]
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [ AuthAnonGuardService ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
