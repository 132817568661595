import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthHttpService } from '../../services/auth-http.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ])
  });
  success = false;


  constructor(private authService: AuthHttpService) { }

  ngOnInit() {
  }

  login() {
    if (this.form.invalid) {
      return;
    }

    this.authService.requestToken(this.form.controls.email.value).subscribe((success) => {
      this.success = true;
    }, (error) => {
      this.success = false;
    });
  }
}
