<section class="header" *ngIf="resource !== undefined && resourceUsers !== undefined">
  <div class="row">
    <div class="col-md-12">
      <h1>{{ 'pages.resource.detail.pageTitle' | translate: { resourceName: resource.name } }}</h1>
    </div>
  </div>
</section>

<section class="content">


  <div class="row" *ngIf="resource !== undefined && resourceUsers !== undefined">
    <div class="col-md-12 col-lg-6">

      <div class="card">
        <div class="card-header">
          {{'pages.resource.detail.infoTitle' | translate}}
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <table class="inplace">
              <tr>
                <td>{{'fields.name' | translate}}:</td>
                <td>
                  <app-inplace-input [controlName]='"name"' (focusOut)='saveForm()' [type]='"text"'></app-inplace-input>
                </td>
              </tr>
              <tr>
                <td>{{'fields.active' | translate}}:</td>
                <td class="non-editable">{{(resource.active ? 'global.activated': 'global.deactivated') | translate}}
                  <span *ngIf="resource.active">(<a [routerLink]="" (click)="disable()">Deactiveren</a>)</span><span
                    *ngIf="!resource.active">(<a [routerLink]="" (click)="enable()">Activeren</a>)</span></td>
              </tr>
              <tr>
                <td>{{'fields.customerNumber' | translate}}:</td>
                <td>
                  <app-inplace-input [controlName]='"customerNumber"' (focusOut)="saveForm()" [type]='"number"'>
                  </app-inplace-input>
                </td>
              </tr>
              <tr>
                <td>{{'fields.maxUserCount' | translate}}:</td>
                <td>
                  <app-inplace-input [controlName]='"maxUserCount"' (focusOut)="saveFormMax()" [type]='"number"'>
                  </app-inplace-input>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <div class="card">
        <div class="card-header">
          <i class="right-button fas fa-plus" (click)="inviteUser()"></i>
          {{'pages.resource.detail.userTitle' | translate: { total: resourceUsers.data.activeUsers, max: resource.maxUserCount} }}
        </div>
        <div class="card-body full">
          <table>
            <thead>
              <th>{{'fields.name' | translate}}</th>
              <th>{{'fields.email' | translate}}</th>
              <th>{{'fields.status' | translate}}</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let user of resourceUsers.data.data">
                <td>{{ user.firstname }} {{ user.lastname }}</td>
                <td>{{ user.email }}</td>
                <td>{{'global.userState.' + user.state | translate}}</td>
                <td *ngIf="user.state == 0">
                  <span class="fas fa-lock danger" [routerLink]="" (click)="disableUser(user)"></span>
                </td>
                <td *ngIf="user.state == 1">
                  <span class="fas fa--unlock success" [routerLink]="" (click)="enableUser(user)"></span>
                </td>
                <td *ngIf="user.state == 2">
                  <span class="fa fa-close danger" [routerLink]="" (click)="deleteInvite(user)"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="error">
    <div class="col-md-12 col-lg-6">

      <div class="card">
        <div class="card-header">
          Informatie
        </div>
        <div class="card-body">
          Niet gevonden.
        </div>
      </div>
    </div>
  </div>
</section>
