<section class="header">
    <div class="row">
      <div class="col-md-12">
        <h1 style="float: left;">{{'pages.invoice.list.pageTitle' | translate}}</h1>
        <div style="float: right"><button class="btn btn-success" (click)="create()"
             >{{'pages.invoice.list.create' | translate}}</button></div>
      </div>
    </div>
  </section>
  
  <section class="content" *ngIf="invoices !== undefined">
  
    <div class="row">
      <div class="col-md-12 col-lg-12 full">
        <table>
          <thead>
            <th>{{ 'fields.name' | translate }}</th>
            <th>{{ 'fields.customer' | translate }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr class="clickable" [routerLink]="[ '/invoice', invoice.id ]"  *ngFor="let invoice of invoices.data">
              <td>{{ invoice.references }}</td>
              <td>
                {{ invoice.contact.organisationName == null ? "Particulier": invoice.contact.organisationName  }} ( {{ invoice.contact.firstname }} {{ invoice.contact.lastname }} )
              </td>
              <td>
                <i class="fas fa-pencil-alt edit" [routerLink]="[ '/invoice', invoice.id ]"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  
    <div class="row paging">
      <div class="col-md-12 col-lg-12 full">
        <app-pagination #paging [currentOffset]="0" [total]="1" [pageSize]="5"></app-pagination>
        </div>
    </div>
  
  </section>
  