import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceHttpService } from '../../services/service-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from '../../models/contact';
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { MatDialog } from '@angular/material/dialog';
import { AssignOrganisationDialogComponent } from '../../dialogs/assign-organisation-dialog/assign-organisation-dialog.component';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {

  id: number;
  contact: Contact;

  form = new FormGroup({
    firstname: new FormControl('Loading', [ Validators.required, Validators.maxLength(200) ]),
    lastname: new FormControl('', [ Validators.required, Validators.maxLength(200) ]),
    email: new FormControl('', [Validators.email, Validators.maxLength(200) ]),
    function: new FormControl('', [ Validators.maxLength(200) ]),
    telephone: new FormControl('',  [ Validators.maxLength(20) ]),
    mobile: new FormControl('',  [ Validators.maxLength(20) ])
  });

  constructor(private httpService: ServiceHttpService,
              private route: ActivatedRoute,
              public snackBar: Snackbar,
              public matDialog: MatDialog,
              private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.id = data.id;

      this.load();
    });
  }

  setForm() {
    this.form.controls.firstname.setValue(this.contact.firstname);
    this.form.controls.lastname.setValue(this.contact.lastname);
    this.form.controls.email.setValue(this.contact.email);
    this.form.controls.function.setValue(this.contact.function);
    this.form.controls.telephone.setValue(this.contact.telephone);
    this.form.controls.mobile.setValue(this.contact.mobile);
  }

  load() {
    this.httpService.get(this.id).subscribe((success) => {
      this.contact = success.data;
      this.setForm();
    }, (error) => {
      if (error.status === 404)
      {
        this.router.navigate([ '/contact' ]);
      } });
  }

  openAssign() {
    this.matDialog.open(AssignOrganisationDialogComponent,  {
      data: this.contact
    }).afterClosed().subscribe(result => {
      this.load();
    });
  }

  saveForm() {
    if (this.form.dirty && this.form.valid) {
      const origObject = new Contact();
      origObject.organisationId = this.contact.organisationId;
      origObject.firstname = this.form.controls.firstname.value;
      origObject.lastname = this.form.controls.lastname.value;
      origObject.email = this.form.controls.email.value;
      origObject.function = this.form.controls.function.value;
      origObject.mobile = this.form.controls.mobile.value;
      origObject.telephone = this.form.controls.telephone.value;
      origObject.organisationName = this.contact.organisationName;

      this.contact = origObject;
      this.form.markAsPristine();

      this.httpService.put(this.id, origObject).subscribe((success) => {
        this.snackBar.openSuccess('pages.contact.detail.saved');
      });
    }
  }

}
