import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from 'src/app/models/Response';
import { HttpClient } from '@angular/common/http';
import { RegisterForm } from '../../models/register-form';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { VerificationResponse } from '../../models/registeration-response';

@Injectable({
  providedIn: 'root',
})
export class RegistrationHttpService {
  constructor(private httpClient: HttpClient) {}

  verifyKey(invitationKey: string): Observable<ObjectResponse<VerificationResponse>> {
    return this.httpClient.post<ObjectResponse<VerificationResponse>>('/api/v1/Auth/verify-key', { invitationKey });
  }

  register(model: RegisterForm): Observable<Response> {
    return this.httpClient.post<Response>('/api/v1/Auth/register', model);
  }
}
