<section class="header" *ngIf="response !== undefined">
  <div class="row">
    <div class="col-md-12">
      <h1 style="float: left;">{{'pages.resource.list.pageTitle' | translate}}</h1>
      <div style="float: right"><button class="btn btn-success" [routerLink]=""
          (click)="create()">{{'pages.resource.list.makeResource' | translate}}</button></div>
    </div>
  </div>
</section>

<section class="content">

  <div class="row" *ngIf="response !== undefined">
    <div class="col-md-12 col-lg-12 full">
          <table>
            <thead>
              <th>{{'fields.id' | translate}}</th>
              <th>{{'fields.name' | translate}}</th>
              <th>{{'fields.customerNumber' | translate}}</th>
              <th>{{'fields.active' | translate}}</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let resource of response.data" [routerLink]="[ '/resource/', resource.id ]" class="clickable">
                <td>{{ resource.id }}</td>
                <td>{{ resource.name }}</td>
                <td>{{ resource.customerNumber }}</td>
                <td>{{(resource.active ? 'global.activated': 'global.deactivated') | translate}}</td>
                <td>
                  <i class="fas fa-pencil-alt edit" [routerLink]="[ '/resource/', resource.id ]"></i>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
  </div>
</section>
