import { Contact } from "../../contact/models/contact";
import { InvoiceRule } from "./invoice-rule"

export class Invoice {
    id: number;
    invoiceNumber: string;
    number: number;
    references: string;
    subTotal: number;
    vat: number;
    total: number;
    contactId: number;
    contact: Contact;
    date: Date;

    rules: InvoiceRule[];
}
