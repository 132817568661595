export class Contact {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    function: string;
    mobile: string;
    telephone: string;
    organisationName: string;
    organisationId: number | null;
}
