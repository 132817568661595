import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ResponseMessage } from 'src/app/models/Response';
import { Contact } from '../../models/contact';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceHttpService } from '../../services/service-http.service';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent implements OnInit {

  form = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.maxLength(70)]),
    lastname: new FormControl('', [Validators.required, Validators.maxLength(70)])
  });

  response: ObjectResponse<Contact>;
  genericErrors: ResponseMessage[];

  constructor(public dialogRef: MatDialogRef < CreateContactDialogComponent >,
              private serviceService: ServiceHttpService) {}

  ngOnInit(): void {
  }

  mapResponse() {
    if (!this.response) {
      return;
    }

    this.genericErrors = [];

    this.response.errors.forEach((message) => {

      if (message.field === '') {
        this.genericErrors.push(message);
      } else {
        const error = {};
        error[message.code] = true;

        this.form.controls[message.field].setErrors(error);
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.invalid) {
      return;
    }

    this.serviceService.create(
      this.form.controls.firstname.value,
      this.form.controls.lastname.value
    ).subscribe((success) => {
      this.dialogRef.close({
        finished: true,
        id: success.data.id
      });
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });
  }


  close() {
    this.dialogRef.close();
  }
}
