<p><strong>{{'pages.auth.forgot-password.request.pageInfo' | translate}}</strong></p>
<div class="alert alert-success" *ngIf="success">
  <strong>{{'global.success' | translate}}</strong><br>{{'pages.auth.forgot-password.request.success' | translate}}
</div>
<form [formGroup]="form" (ngSubmit)="login()">
  <div class="form-group" *ngIf="!success">
    <label>{{'fields.email' | translate}}:</label>
    <input type="email" class="form-control full-width" formControlName="email" />
    <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="control-error">
      <ul>
        <li *ngFor="let error of form.controls['email'].errors | objToKeys">
          {{ 'errors.email.' + error | translate }}
        </li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <button class="btn btn-primary btn-full-width" [disabled]="form.invalid"  *ngIf="!success">{{'pages.auth.forgot-password.request.mailMe' | translate}}</button><br />
    <a [routerLink]="[ '/auth/login'] ">{{'pages.auth.back' | translate}}</a>
  </div>
</form>