<div class="vertical-center">
  <div class="container">
    <div class="row no-gutters">
  
      <div class="content col-md-8 offset-md-3">
        <div class="container-fluid fill">
          <div class="row fill">
            <div class="col-sm-12 col-lg-4 info">
              <h1>DuoCRM</h1>
              Het meest awesome CRM die er is :).
            </div>
            <div class="col-sm-8 inner">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>