import { MyOrganisation } from "../models/MyOrganisation";

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectResponse } from 'src/app/models/ObjectResponse';

@Injectable({
  providedIn: 'root',
})
export class MyOrganisationHttpService {
  constructor(private httpClient: HttpClient) { }

  get(): Observable<ObjectResponse<MyOrganisation>> {
    return this.httpClient.get<ObjectResponse<MyOrganisation>>(`/api/v1/my-organisation`);
  }

  create(organisation: MyOrganisation): Observable<ObjectResponse<MyOrganisation>> {
    return this.httpClient.post<ObjectResponse<MyOrganisation>>(`/api/v1/my-organisation`, organisation);
  }
}
