import {
  Component,
  OnInit
} from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  InviteUserDialogComponent
} from '../../dialogs/invite-user-dialog/invite-user-dialog.component';
import {
  Resource
} from '../../models/resource';
import {
  ConfirmationDialogComponent
} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute
} from '@angular/router';
import {
  ResourceHttpService
} from '../../services/resource-http.service';
import {
  ResourceUser
} from '../../models/resource-user';
import {
  ListResponse
} from 'src/app/models/list-response';
import {
  ResourceUserHttpService
} from '../../services/resource-user-http.service';
import {
  Observable
} from 'rxjs';
import {
  Snackbar
} from 'src/app/shared/helper/snackbar';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { ResourceUserList } from '../../models/resource-user-list';
import { ObjectResponse } from 'src/app/models/ObjectResponse';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss']
})
export class ResourceDetailComponent implements OnInit {

  resource: Resource;
  resourceUsers: ObjectResponse < ResourceUserList > ;
  error: boolean;
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    customerNumber: new FormControl('', [Validators.min(1)]),
    maxUserCount: new FormControl('', [Validators.required, Validators.min(1), Validators.max(200)])
  });

  constructor(public matDialog: MatDialog,
              private route: ActivatedRoute,
              public snackBar: Snackbar,
              private httpService: ResourceHttpService,
              private userHttpService: ResourceUserHttpService) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.loadResource(data.id).subscribe((success) => {
        this.loadResourceUsers();
      });
    });
  }

  saveForm() {
    if (this.form.dirty && this.form.valid) {
      const resource = new Resource();
      resource.id = this.resource.id;
      resource.name = this.form.controls.name.value;
      resource.maxUserCount = +this.form.controls.maxUserCount.value;
      resource.customerNumber = this.form.controls.customerNumber.value !== '' ? +this.form.controls.customerNumber.value : null;
      resource.active = this.resource.active;

      this.httpService.editUserCount(this.resource.id, resource).subscribe((success) => {
        this.snackBar.openSuccess('pages.resource.detail.confirmations.resourceSaved');

        this.resource = resource;
        this.form.markAsPristine();
      }, (error) => {
        this.snackBar.openError(error.error.ToJson());
        // @TODO: Fix error
      });
    }
  }

  saveFormMax() {
    if (this.form.dirty) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'pages.resource.detail.confirmations.editUserCountTitle',
          message: 'pages.resource.detail.confirmations.editUserCountMessage'
        }
      }).afterClosed().subscribe(result => {
        if (result.confirmed) {
          this.saveForm();
        } else {
          this.form.controls.maxUserCount.setValue(this.resource.maxUserCount);
        }
      });
    }
  }

  loadResource(id: number): Observable < boolean > {
    const observable = Observable.create(observer => {
      this.httpService.getResourceById(id).subscribe((success) => {
        this.resource = success.data;
        this.form.controls.name.setValue(this.resource.name);
        this.form.controls.maxUserCount.setValue(this.resource.maxUserCount);
        this.form.controls.customerNumber.setValue(this.resource.customerNumber);
        observer.next(true);
      }, (error) => {
        this.error = true;
      });
    });

    return observable;
  }

  loadResourceUsers() {
    this.userHttpService.listResource(this.resource.id, 100, 0).subscribe((success) => {
      this.resourceUsers = success;
    }, (error) => {});
  }

  disable() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.resource.detail.confirmations.disableTitle',
        message: 'pages.resource.detail.confirmations.disableMessage'
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.httpService.disable(this.resource.id).subscribe((success) => {
          this.loadResource(this.resource.id).subscribe();

          this.snackBar.openSuccess('pages.resource.detail.confirmations.disableSnackbar');
        }, (error) => {

        });
      }
    });
  }

  enable() {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.resource.detail.confirmations.activateTitle',
        message: 'pages.resource.detail.confirmations.activateMessage'
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.httpService.enable(this.resource.id).subscribe((success) => {
          this.loadResource(this.resource.id).subscribe();
          this.snackBar.openSuccess('pages.resource.detail.confirmations.activateSnackbar');
        }, (error) => {

        });
      }
    });
  }

  inviteUser() {
    // Reload users after change
    this.matDialog.open(InviteUserDialogComponent, {
      data: this.resource
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.loadResourceUsers();
        this.snackBar.openSuccess('pages.resource.detail.confirmations.inviteUserSnackbar');
      }
    });
  }

  disableUser(user: ResourceUser) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.resource.detail.confirmations.disableUserTitle',
        message: 'pages.resource.detail.confirmations.disableUserMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.userHttpService.disableUser(this.resource.id, user.id).subscribe((success) => {
          this.loadResourceUsers();
          this.snackBar.openSuccess('pages.resource.detail.confirmations.disableUserSnackbar');
        });
      }
    });
  }

  enableUser(user: ResourceUser) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.resource.detail.confirmations.enableUserTitle',
        message: 'pages.resource.detail.confirmations.enableUserMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.userHttpService.enableUser(this.resource.id, user.id).subscribe((success) => {
          this.loadResourceUsers();
          this.snackBar.openSuccess('pages.resource.detail.confirmations.enableUserSnackbar');
        }, (error) => {
          if (error.error.errors.every(u => u.code === 'invalidstate')) {
            this.snackBar.openError('U heeft het gebruikers limiet bereikt.');
          }
        });
      }
    });
  }

  deleteInvite(user: ResourceUser) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.resource.detail.confirmations.inviteUserTitle',
        message: 'pages.resource.detail.confirmations.inviteUserMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.userHttpService.deleteInvite(this.resource.id, user.email).subscribe((success) => {
          this.loadResourceUsers();
          this.snackBar.openSuccess('pages.resource.detail.confirmations.deleteInviteUserSnackbar');
        }, (error) => {

        });
      }
    });
  }
}
