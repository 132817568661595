import { Contact } from "../models/contact";

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { ListResponse } from 'src/app/models/list-response';

@Injectable({
    providedIn: 'root',
  })
export class ServiceHttpService {
  constructor(private httpClient: HttpClient) {}

  list(take: number, skip: number): Observable<ListResponse<Contact>> {
      return this.httpClient.get<ListResponse<Contact>>(`/api/v1/contact?take=${take}&skip=${skip}`);
  }

  get(id: number): Observable<ObjectResponse<Contact>> {
      return this.httpClient.get<ObjectResponse<Contact>>(`/api/v1/contact/${id}`);
      }

  put(id: number, contact: Contact): Observable<ObjectResponse<Contact>> {
    return this.httpClient.patch<ObjectResponse<Contact>>(`/api/v1/contact/${id}`, contact);
  }

  create(firstname: string, lastname: string): Observable<ObjectResponse<Contact>> {
    const contact = new Contact();
    contact.firstname = firstname;
    contact.lastname = lastname;

    return this.httpClient.post<ObjectResponse<Contact>>(`/api/v1/contact`, contact);
  }
}
