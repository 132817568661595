import {
  Component,
  OnInit
} from '@angular/core';
import {
  AuthService
} from 'src/app/shared/auth/auth.service';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  failed = false;
  faRequired = false;

  form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  faForm = new FormGroup({
    twoToken: new FormControl('')
  });

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  login() {
    if (this.form.invalid) {
      return;
    }

    this.authService.login(this.form.value.username,
      this.form.value.password, null).subscribe(loginToken => {
      this.failed = loginToken === 0;
      this.faRequired = loginToken === 2;

      if (!this.failed && !this.faRequired) {
        this.router.navigate(['/']);
      }
    });
  }

  sendFa() {
    if (this.form.invalid) {
      return;
    }

    this.authService.login(this.form.value.username,
      this.form.value.password, this.faForm.value.twoToken).subscribe(loginToken => {
      this.failed = loginToken === 0;

      if (!this.failed) {
        this.router.navigate(['/']);
      }
    });
  }
}
