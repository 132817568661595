import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RegistrationHttpService } from '../services/registration-http.service';
import { Response } from '../../../../../models/Response';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  codeForm = new FormGroup({
    code: new FormControl('', [ Validators.required ])
  });

  registerForm: FormGroup;

  code: string;
  invalidCode: boolean;
  error: Response;
  success: boolean;

  constructor(private route: ActivatedRoute, private registrationService: RegistrationHttpService, private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      this.code = params.code;
    });
    this.registerForm = this.formBuilder.group({
      email: new FormControl('', [ ]),
      firstname: new FormControl('', [ Validators.required ]),
      lastname: new FormControl('', [ Validators.required ]),
      password: new FormControl('', [ Validators.required ]),
      passwordConf: new FormControl('', [ Validators.required ])
    });
    this.registerForm.setValidators(this.comparisonValidator());
   }

   public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls.password;
      const control2 = group.controls.passwordConf;
      if (control1.value !== '' && control1.value !== control2.value) {
         control2.setErrors({notsame: true});
      } else {
         control2.setErrors(null);
      }
      return;
    };
  }

  ngOnInit() {
    if (this.code !== undefined) {
      this.registrationService.verifyKey(this.code).subscribe((success) => {
        this.registerForm.controls.email.setValue(success.data.email);
      }, (error) => {
        this.invalidCode = true;
        this.codeForm.controls.code.setValue(this.code);
        this.code = null;
      });
    }
  }

  mapResponse() {
    if (!this.error) {
      return;
    }

    this.error.errors.forEach((message) => {

      const error = {};
      error[message.code] = true;

      console.log(error);

      this.registerForm.controls[message.field].setErrors(error);
    });
  }

  register() {
    if (this.registerForm.invalid) {
      return;
    }

    this.registrationService.register({
      password: this.registerForm.controls.password.value,
      email: this.registerForm.controls.email.value,
      firstname: this.registerForm.controls.firstname.value,
      lastname: this.registerForm.controls.lastname.value,
      invitationKey: this.code
    }).subscribe((success) => {
      this.success = true;
    }, (error) => {
      this.error = error.error;
      this.mapResponse();
    });
  }

  verify() {
    if (this.codeForm.invalid) {
      return;
    }

    this.registrationService.verifyKey(this.codeForm.controls.code.value).subscribe((success) => {
      this.invalidCode = false;
      this.code = this.codeForm.controls.code.value;
      this.registerForm.controls.email.setValue(success.data.email);
    }, (error) => {
      this.invalidCode = true;
    });
  }
}
