import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from 'src/app/models/Response';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../models/resource';
import { ListResponse } from 'src/app/models/list-response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';

@Injectable({
  providedIn: 'root',
})
export class ResourceHttpService {
  constructor(private httpClient: HttpClient) {}

  listResource(take: number, skip: number): Observable<ListResponse<Resource>> {
    return this.httpClient.get<ListResponse<Resource>>(`/api/v1/resource?take=${take}&skip=${skip}`);
  }

  getResourceById(id: number): Observable<ObjectResponse<Resource>> {
    return this.httpClient.get<ObjectResponse<Resource>>(`/api/v1/resource/${id}`);
  }

  create(resource: Resource) {
    return this.httpClient.post<ObjectResponse<Resource>>(`/api/v1/resource`, resource);
  }

  enable(id: number) {
    return this.httpClient.post<ObjectResponse<Resource>>(`/api/v1/resource/${id}/enable`, {});
  }

  disable(id: number) {
    return this.httpClient.post<ObjectResponse<Resource>>(`/api/v1/resource/${id}/disable`, {});
  }

  editUserCount(id: number, resource: Resource) {
    return this.httpClient.patch<ObjectResponse<Resource>>(`/api/v1/resource/${id}`, resource);
  }
}
