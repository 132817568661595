import { DatePipe } from '@angular/common';
import { Component, OnInit, EventEmitter, Input, Output, ElementRef, ViewChildren, ViewChild } from '@angular/core';
import { FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inplace-input',
  templateUrl: './inplace-input.component.html',
  styleUrls: ['./inplace-input.component.scss']
})
export class InplaceInputComponent implements OnInit {

  data: string;
  @Input() type = 'text';
  @Input() controlName: string;
  @Input() values: string[];
  @Input() alwaysOut = false;
  @Input() form: FormGroup;
  @Input() format = 'none';
  @Input() formatValue: string;
  @Input() next: InplaceInputComponent;

  @Output() focusOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() lastTabAction: EventEmitter<InplaceInputComponent> = new EventEmitter<InplaceInputComponent>();

  editMode = false;
  errorPrefix: string;
  public formControl: FormControl;
  @ViewChild('control') control: ElementRef;

  constructor(private parent: FormGroupDirective) { }

  ngOnInit() {
    if (this.type === 'select') {
      this.editMode = true;
    }

    if (this.form == undefined) {
      this.formControl = this.parent.form.get(this.controlName) as FormControl;
    }
    else {
      this.formControl = this.form.get(this.controlName) as FormControl;
    }

    this.errorPrefix = `errors.${this.controlName}.`;

    if (this.alwaysOut) {
      this.enableEditMode();
    }
  }

  enableEditMode() {
    this.editMode = true;
    this.data = this.formControl.value;
    setTimeout(() => {
      this.control.nativeElement.focus();
    }, 0);
  }

  onFocusOut() {
    if (this.formControl.invalid) {
      this.control.nativeElement.focus();
      return;
    }

    if (this.type !== 'select' && !this.alwaysOut) {
      this.editMode = false;
    }

    this.focusOut.emit(true);
  }

  tab(event) {
    event.preventDefault();

    if (this.next != null) {
      this.next.enableEditMode();

      return;
    }

    this.ClickNextElement();
  }

  ClickNextElement(goAgain = true) {
    if (!this.formControl.valid) {
      return;
    }

    let inputs = document.getElementsByClassName('inplace-input');

    let found = false;

    for (let i = 0; i < inputs.length; i++) {
      if (found) {
        (inputs[i] as HTMLElement).click();

        return;
      }

      if (inputs[i].classList.contains('inplace-active')) {
        found = true;
      }
    }

    if (this.lastTabAction !== undefined) {
      this.lastTabAction.emit(this);

      if (goAgain) {
        setTimeout(() => {
          this.ClickNextElement(false);
        }, 50);
      }
      return;
    }

    (inputs[0] as HTMLElement).click();
  }
}
