import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
  })
export class Snackbar {

  constructor(public snackBar: MatSnackBar,
              private translate: TranslateService
              ) {}

  openSuccess(key: string, data: any = null) {
    this.translate.get(key, data).subscribe((result) => {
        this.snackBar.open(result, null, {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: 'success'
          });
    });
  }

  openError(key: string, data: any = null, duration = 2000) {
    this.translate.get(key, data).subscribe((result) => {
        this.snackBar.open(result, null, {
            duration,
            verticalPosition: 'top',
            panelClass: 'error'
          });
    });
  }
}
