<div *ngIf="!faRequired">
  <p><strong>{{'pages.auth.login.passwordPageInfo' | translate}}</strong></p>
  <div class="alert alert-danger" *ngIf="failed">
    <strong>{{'global.error' | translate}}:</strong><br />
    {{ 'pages.auth.login.passwordInvalid' | translate }}
  </div>
  <form [formGroup]="form" (ngSubmit)="login()">
    <div class="form-group">
      <label>{{'fields.email' | translate}}:</label>
      <input type="text" class="form-control full-width" formControlName="username" />
    </div>
    <div class="form-group">
      <label>{{'fields.password' | translate}}:</label>
      <input type="password" class="form-control full-width" formControlName="password" />
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-full-width" [disabled]="form.invalid">{{'pages.auth.login.passwordSubmit' | translate}}</button><br>
      <a [routerLink]="[ '/auth/forgot-password' ]">{{'pages.auth.login.forgotPassword' | translate}}</a><br>
      <a [routerLink]="[ '/auth/register' ]">{{'pages.auth.login.register' | translate}}</a>
    </div>
  </form>
</div>

<div class="card-body" *ngIf="faRequired">
  <p><strong>{{'pages.auth.login.codePageInfo' | translate}}</strong></p>
  <div class="alert alert-danger" *ngIf="failed">
    <strong>{{'global.error' | translate}}:</strong>
    {{'pages.auth.login.codeInvalid' | translate}}
  </div>
  <form [formGroup]="faForm" (ngSubmit)="sendFa()">
    <div class="form-group">
      <label>{{'fields.twofacode' | translate}}:</label>
      <input type="password" class="form-control full-width" formControlName="twoToken" />
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-full-width"
        [disabled]="form.invalid">{{'global.continue' | translate}}</button>
    </div>
  </form>
</div>