import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ResponseMessage } from 'src/app/models/Response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganisationContactHttpService} from '../../services/organisation-contact-http.service';
import { OrganisationContact } from '../../models/organisation-contact';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-organisation-contact-dialog.html',
  styleUrls: ['./create-organisation-contact-dialog.scss']
})
export class CreateOrganisationContactDialogComponent implements OnInit {

  form = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.maxLength(70)]),
    lastname: new FormControl('', [Validators.required, Validators.maxLength(70)])
  });

  response: ObjectResponse<OrganisationContact>;
  genericErrors: ResponseMessage[];
  organisationId: number;

  constructor(public dialogRef: MatDialogRef < CreateOrganisationContactDialogComponent >,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private httpContactService: OrganisationContactHttpService) {}

  ngOnInit(): void {
    console.log(this.data);
    this.organisationId = this.data;
  }

  mapResponse() {
    if (!this.response) {
      return;
    }

    this.genericErrors = [];

    this.response.errors.forEach((message) => {

      if (message.field === '') {
        this.genericErrors.push(message);
      } else {
        const error = {};
        error[message.code] = true;

        this.form.controls[message.field].setErrors(error);
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.invalid) {
      return;
    }

    let contact = new OrganisationContact();
    contact.firstname = this.form.controls.firstname.value;
    contact.lastname = this.form.controls.lastname.value;

    this.httpContactService.post(
      this.organisationId,
      contact
    ).subscribe((success) => {
      this.dialogRef.close({
        finished: true,
        id: success.data.id
      });
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });
  }


  close() {
    this.dialogRef.close();
  }
}
