<div *ngIf="!code">
  <p><strong>{{'pages.auth.register.codePageInfo' | translate}}</strong></p>
  <div class="alert alert-danger" *ngIf="invalidCode">
    <strong>{{'global.error' | translate}}:</strong><br />
    {{'pages.auth.register.invalidCode' | translate}}
  </div>
  <form [formGroup]="codeForm" (ngSubmit)="verify()">
    <div class="form-group">
      <label>{{'fields.code' | translate}}:</label>
      <input type="text" class="form-control full-width" formControlName="code" />
      <div *ngIf="codeForm.controls['code'].invalid && (codeForm.controls['code'].dirty || codeForm.controls['code'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['code'].errors | objToKeys">
            {{ 'errors.code.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-full-width" [disabled]="codeForm.invalid">{{'pages.auth.register.codeSubmit' | translate}}</button><br>
      <a [routerLink]="[ '/auth/login' ]">{{'pages.auth.back' | translate}}</a>
    </div>
  </form>
</div>

<div *ngIf="code && !success">
  <p><strong>{{'pages.auth.register.registerPageInfo' | translate}}</strong></p>
  
  <div class="alert alert-danger" *ngIf="error !== undefined">
    <strong>{{'global.error' | translate}}:</strong><br />
    {{'global.invalidInput' | translate}}
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="register()">
    <div class="form-group">
      <label>{{'fields.email' | translate}}:</label>
      <input type="email" class="form-control full-width" formControlName="email" disabled />
      <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['email'].errors | objToKeys">
            {{ 'errors.email.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>
    
    <div class="form-group">
      <label>{{'fields.firstname' | translate}}:</label>
      <input type="text" class="form-control full-width" formControlName="firstname" />
      <div *ngIf="registerForm.controls['firstname'].invalid && (registerForm.controls['firstname'].dirty || registerForm.controls['firstname'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['firstname'].errors | objToKeys">
            {{ 'errors.firstname.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <label>{{'fields.lastname' | translate}}:</label>
      <input type="text" class="form-control full-width" formControlName="lastname" />
      <div *ngIf="registerForm.controls['lastname'].invalid && (registerForm.controls['lastname'].dirty || registerForm.controls['lastname'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['lastname'].errors | objToKeys">
            {{ 'errors.lastname.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <label>{{'fields.password' | translate}}:</label>
      <input type="password" class="form-control full-width" formControlName="password" />
      <div *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['password'].errors | objToKeys">
            {{ 'errors.password.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <label>{{'fields.passwordConf' | translate}}:</label>
      <input type="password" class="form-control full-width" formControlName="passwordConf" />
      <div *ngIf="registerForm.controls['passwordConf'].invalid && (registerForm.controls['passwordConf'].dirty || registerForm.controls['passwordConf'].touched)" class="control-error">
        <ul>
          <li *ngFor="let error of registerForm.controls['passwordConf'].errors | objToKeys">
            {{ 'errors.passwordConf.' + error | translate }}
          </li>
        </ul>
      </div>
    </div>

    
    <div class="form-group">
      <button class="btn btn-primary btn-full-width" [disabled]="registerForm.invalid">{{'pages.auth.register.registerSubmit' | translate}}</button><br>
      <a [routerLink]="[ '/auth/login' ]">{{'pages.auth.back' | translate}}</a>
    </div>
  </form>
</div>

<div *ngIf="code && success">
  <p><strong>{{'pages.auth.register.successPageInfo' | translate}}</strong></p>
  {{'pages.auth.register.successPageContent' | translate}}<br>
  <a [routerLink]="[ '/auth/login' ]">{{'pages.auth.back' | translate}}</a>
</div>