<section class="header">
  <div class="row">
    <div class="col-md-12">
      <h1 style="float: left;">{{'pages.employee.list.pageTitle' | translate}}</h1>
      <div style="float: right"><button class="btn btn-success" [routerLink]=""
          (click)="invite()">{{'pages.employee.list.inviteButton' | translate}}</button></div>
    </div>
  </div>
</section>

<section class="content" *ngIf="employees !== undefined">

  <div class="row">
    <div class="col-md-12 col-lg-12 full">

      <table>
        <thead>
          <th>{{'fields.name' | translate}}</th>
          <th>{{'fields.email' | translate}}</th>
          <th>{{'fields.status' | translate}}</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employees.data.data">
            <td>{{ employee.firstname }} {{ employee.lastname }}</td>
            <td>{{ employee.email }}</td>
            <td>{{'global.userState.' + employee.state | translate}}</td>
            <td>
              <i class="fas fa-lock danger" *ngIf="employee.state == 0 && employee.id != authService.getId()"
                [routerLink]="" (click)="disable(employee)"></i>
                <i class="fas fa-unlock success" *ngIf="employee.state == 1 && employee.id != authService.getId()"
                [routerLink]="" (click)="enable(employee)"></i>
                <i class="fas fa-tash-alt danger" *ngIf="employee.state == 2 && employee.id != authService.getId()"
                [routerLink]="" (click)="delete(employee)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row paging">
    <div class="col-md-12 col-lg-12 full">
      <app-pagination #paging [currentOffset]="pageNum * pageSize" [total]="employees.data.total" [pageSize]="pageSize"  (onSelect)="navigate($event)"></app-pagination>
      </div>
  </div>
</section>
