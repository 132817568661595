<section class="header">
    <div class="row">
      <div class="col-md-12">
        <h1 style="float: left;">{{'pages.contact.list.pageTitle' | translate}}</h1>
        <div style="float: right"><button class="btn btn-success" (click)="create()" [routerLink]="">{{'pages.contact.list.create' | translate}}</button></div>
      </div>
    </div>
  </section>
  
  <section class="content" *ngIf="contacts !== undefined">
  
    <div class="row">
      <div class="col-md-12 col-lg-12 full">
        <table>
          <thead>
            <th>{{ 'fields.name' | translate }}</th>
            <th>{{ 'fields.company' | translate }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr class="clickable"  *ngFor="let contact of contacts.data" [routerLink]="[ '/contact', contact.id ]">
              <td>{{ contact.firstname }} {{ contact.lastname }}</td>
              <td>
                {{contact.organisationName || ( "pages.contact.nonBusiness" | translate ) }}
              </td>
              <td>
                <i class="fas fa-pencil-alt edit" [routerLink]="[ '/contact', contact.id ]"></i> 
              </td>
            </tr>
        </table>
      </div>
    </div>

    
  <div class="row paging">
    <div class="col-md-12 col-lg-12 full">
      <app-pagination #paging [currentOffset]="pageNum * pageSize" [total]="contacts.total" [pageSize]="pageSize"  (onSelect)="navigate($event)"></app-pagination>
      </div>
  </div>
  </section>
  