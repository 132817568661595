import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ListResponse } from 'src/app/models/list-response';
import { ResourceUser } from '../models/resource-user';
import { Response } from 'src/app/models/Response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { ResourceUserList } from '../models/resource-user-list';

@Injectable({
  providedIn: 'root',
})
export class ResourceUserHttpService {
  constructor(private httpClient: HttpClient) {}

  listResource(resourceId: number, take: number, skip: number): Observable<ObjectResponse<ResourceUserList>> {
    return this.httpClient.get<ObjectResponse<ResourceUserList>>(`/api/v1/resource/${resourceId}/users?take=${take}&skip=${skip}`);
  }

  inviteUser(resourceId: number, email: string): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/resource/${resourceId}/users/invite`, { email });
  }

  enableUser(resourceId: number, id: number): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/resource/${resourceId}/users/${id}/enable`, { });
  }

  disableUser(resourceId: number, id: number): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/resource/${resourceId}/users/${id}/disable`, { });
  }

  deleteInvite(resourceId: number, email: string): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/resource/${resourceId}/users/delete-invite`, { email });
  }
}
