import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggedinComponent } from './shared/components/loggedin/loggedin.component';
import { LoginComponent } from './features/auth/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { ReactiveFormsModule, FormsModule, FormGroupDirective } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptorService } from './shared/auth/token-interceptor.service';
import { ProfileComponent } from './features/my-profile/components/profile/profile.component';
import { TwoFaComponent } from './features/my-profile/components/two-fa/two-fa.component';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResourceListComponent } from './features/resource/components/resource-list/resource-list.component';
import { AuthAnonGuardService } from './shared/auth/auth-anon.service';
import { ResourceDetailComponent } from './features/resource/components/resource-detail/resource-detail.component';
import { AuthAdminGuardService } from './shared/auth/auth-adminguard.service';
import { UnauthenticatedComponent } from './shared/components/unauthenticated/unauthenticated.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './features/auth/forgot-password/components/reset-password/reset-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InviteUserDialogComponent } from './features/resource/dialogs/invite-user-dialog/invite-user-dialog.component';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CreateResourceDialogComponent } from './features/resource/dialogs/create-resource-dialog/create-resource-dialog.component';
import { RegisterComponent } from './features/auth/register/components/register/register.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ObjToKeysPipe } from 'src/obj-to-keys-pipe';
import { InplaceInputComponent } from './shared/components/inplace-input/inplace-input.component';
import { InviteDialogComponent } from './features/employee/dialogs/invite-dialog/invite-dialog.component';
import { OrganisationListComponent } from './features/organisation/components/organisation-list/organisation-list.component';
import { OrganisationDetailComponent } from './features/organisation/components/organisation-detail/organisation-detail.component';
import { ProfileMenuComponent } from './shared/components/loggedin/components/profile-menu/profile-menu.component';
import { SideMenuComponent } from './shared/components/loggedin/components/side-menu/side-menu.component';
import {MatTabsModule} from '@angular/material/tabs';
import { InplaceSelectComponent } from './shared/components/inplace-select/inplace-select.component';
import { CreateDialogComponent } from './features/organisation/dialogs/create-dialog/create-dialog.component';
import { ContactListComponent } from './features/contact/components/contact-list/contact-list.component';
import { ContactDetailComponent } from './features/contact/components/contact-detail/contact-detail.component';
import { CreateContactDialogComponent } from './features/contact/dialogs/create-contact-dialog/create-contact-dialog.component';
import { AssignOrganisationDialogComponent } from './features/contact/dialogs/assign-organisation-dialog/assign-organisation-dialog.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { CreateOrganisationContactDialogComponent } from './features/organisation/dialogs/create-organisation-contact-dialog/create-organisation-contact-dialog';
import { InvoiceDetailComponent } from './features/invoice/components/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './features/invoice/components/invoice-list/invoice-list.component';
import { EmployeeListComponent } from './features/employee/components/employeelist/employee-list.component';
import { CreateInvoiceDialogComponent } from './features/invoice/dialogs/create-invoice-dialog/create-invoice-dialog.component';
import { MyOrganizationComponent } from './features/my-organisation/components/my-organisation/my-organisation.component';

@NgModule({
    declarations: [
        AppComponent,
        LoggedinComponent,
        LoginComponent,
        DashboardComponent,
        ProfileComponent,
        TwoFaComponent,
        ResourceListComponent,
        ResourceDetailComponent,
        UnauthenticatedComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        InviteUserDialogComponent,
        ConfirmationDialogComponent,
        CreateResourceDialogComponent,
        RegisterComponent,
        ObjToKeysPipe,
        InplaceInputComponent,
        EmployeeListComponent,
        InviteDialogComponent,
        OrganisationDetailComponent,
        OrganisationListComponent,
        ProfileMenuComponent,
        SideMenuComponent,
        InplaceSelectComponent,
        CreateDialogComponent,
        ContactListComponent,
        ContactDetailComponent,
        CreateContactDialogComponent,
        CreateOrganisationContactDialogComponent,
        PaginationComponent,
        AssignOrganisationDialogComponent,
        InvoiceDetailComponent,
        InvoiceListComponent,
        CreateInvoiceDialogComponent,
        MyOrganizationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        QRCodeModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatTabsModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule
    ],
    providers: [
        AuthGuardService,
        AuthAnonGuardService,
        AuthAdminGuardService,
        FormGroupDirective,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
 }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
