import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Organisation } from '../../models/organisation';
import { OrganisationHttpService } from '../../services/organisation-http.service';
import { OrganisationContactHttpService } from '../../services/organisation-contact-http.service';
import {
  ActivatedRoute, Router
} from '@angular/router';
import { Address } from '../../models/address';
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { ListResponse } from 'src/app/models/list-response';
import { OrganisationContact } from '../../models/organisation-contact';
import { MatDialog } from '@angular/material/dialog';
import { CreateOrganisationContactDialogComponent } from '../../dialogs/create-organisation-contact-dialog/create-organisation-contact-dialog';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss']
})
export class OrganisationDetailComponent implements OnInit {

  id: number;
  organisation: Organisation;
  pageSize = 10;
  pageNum: number;

  @ViewChild('paging') paging;

  contacts: ListResponse < OrganisationContact > ;
  
  form = new FormGroup({
    name: new FormControl('', [ Validators.required ]),
    type: new FormControl(''),
    email: new FormControl('', [ Validators.email ]),
    telephone: new FormControl(''),
    website: new FormControl(''),
    customerNumber: new FormControl(''),
    cocnumber: new FormControl(''),
    vatnumber: new FormControl(''),

    visitAddressStreet: new FormControl(''),
    visitAddressHousenumber: new FormControl(''),
    visitAddressPostalcode: new FormControl(''),
    visitAddressCity: new FormControl(''),
    visitAddressState: new FormControl(''),
    visitAddressCountry: new FormControl(''),

    invoiceAddressStreet: new FormControl(''),
    invoiceAddressHousenumber: new FormControl(''),
    invoiceAddressPostalcode: new FormControl(''),
    invoiceAddressCity: new FormControl(''),
    invoiceAddressState: new FormControl(''),
    invoiceAddressCountry: new FormControl(''),

    postalAddressStreet: new FormControl(''),
    postalAddressHousenumber: new FormControl(''),
    postalAddressPostalcode: new FormControl(''),
    postalAddressCity: new FormControl(''),
    postalAddressState: new FormControl(''),
    postalAddressCountry: new FormControl('')
  });

  types = [
    'supplier',
    'lead',
    'prospect',
    'hot_prospect',
    'customer',
    'suspect',
    'partner',
    'daughter_organisation',
    'competitor'
  ];

  constructor(private httpService: OrganisationHttpService,
              private httpContactService: OrganisationContactHttpService,
              private route: ActivatedRoute,
              public matDialog: MatDialog,
              private snackBar: Snackbar,
              private router: Router) {
               }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.id = data.id;
      this.pageNum = +data.page || 0;

      this.loadOrganisation();
    });
  }

  createContact()
  {
    this.matDialog.open(CreateOrganisationContactDialogComponent, {
      data: this.organisation.id
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.router.navigate([ 'contact', result.id ]);
      }
    });
  }

  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.httpContactService.get(this.organisation.id, this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.contacts = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
    }, (error) => {
      // We should error here
    });
  }

  saveForm() {
    if (this.form.dirty && this.form.valid) {
      const origObject = new Organisation();
      origObject.name = this.form.controls.name.value;
      origObject.email = this.form.controls.email.value;
      origObject.type = this.form.controls.type.value;
      origObject.website = this.form.controls.website.value;
      origObject.telephone = this.form.controls.telephone.value;
      origObject.chamberOfCommerce = this.form.controls.cocnumber.value;
      origObject.customerCode = this.form.controls.customerNumber.value;
      origObject.vatNumber = this.form.controls.vatnumber.value;

      origObject.invoiceAddress = new Address();
      origObject.invoiceAddress.street = this.form.controls.invoiceAddressStreet.value;
      origObject.invoiceAddress.houseNumber = this.form.controls.invoiceAddressHousenumber.value;
      origObject.invoiceAddress.postalCode = this.form.controls.invoiceAddressPostalcode.value;
      origObject.invoiceAddress.place = this.form.controls.invoiceAddressCity.value;
      origObject.invoiceAddress.state = this.form.controls.invoiceAddressState.value;
      origObject.invoiceAddress.country = this.form.controls.invoiceAddressCountry.value;

      origObject.postalAddress = new Address();
      origObject.postalAddress.street = this.form.controls.postalAddressStreet.value;
      origObject.postalAddress.houseNumber = this.form.controls.postalAddressHousenumber.value;
      origObject.postalAddress.postalCode = this.form.controls.postalAddressPostalcode.value;
      origObject.postalAddress.place = this.form.controls.postalAddressCity.value;
      origObject.postalAddress.state = this.form.controls.postalAddressState.value;
      origObject.postalAddress.country = this.form.controls.postalAddressCountry.value;

      origObject.visitAddress = new Address();
      origObject.visitAddress.street = this.form.controls.visitAddressStreet.value;
      origObject.visitAddress.houseNumber = this.form.controls.visitAddressHousenumber.value;
      origObject.visitAddress.postalCode = this.form.controls.visitAddressPostalcode.value;
      origObject.visitAddress.place = this.form.controls.visitAddressCity.value;
      origObject.visitAddress.state = this.form.controls.visitAddressState.value;
      origObject.visitAddress.country = this.form.controls.visitAddressCountry.value;

      this.organisation = origObject;
      this.form.markAsPristine();

      this.httpService.put(this.id, origObject).subscribe((success) => {
        this.snackBar.openSuccess('pages.organisation.detail.saved');
      });
    }
  }

  setForm() {
    this.form.controls.name.setValue(this.organisation.name);
    this.form.controls.customerNumber.setValue(this.organisation.customerCode);
    this.form.controls.type.setValue(this.organisation.type);
    this.form.controls.email.setValue(this.organisation.email);
    this.form.controls.website.setValue(this.organisation.website);
    this.form.controls.telephone.setValue(this.organisation.telephone);
    this.form.controls.cocnumber.setValue(this.organisation.chamberOfCommerce);
    this.form.controls.vatnumber.setValue(this.organisation.vatNumber);

    if (this.organisation.visitAddress !== null)
    {
      this.form.controls.visitAddressStreet.setValue(this.organisation.visitAddress.street);
      this.form.controls.visitAddressState.setValue(this.organisation.visitAddress.state);
      this.form.controls.visitAddressPostalcode.setValue(this.organisation.visitAddress.postalCode);
      this.form.controls.visitAddressHousenumber.setValue(this.organisation.visitAddress.houseNumber);
      this.form.controls.visitAddressCity.setValue(this.organisation.visitAddress.place);
      this.form.controls.visitAddressCountry.setValue(this.organisation.visitAddress.country);
    }

    if (this.organisation.invoiceAddress !== null)
    {
      this.form.controls.invoiceAddressStreet.setValue(this.organisation.invoiceAddress.street);
      this.form.controls.invoiceAddressState.setValue(this.organisation.invoiceAddress.state);
      this.form.controls.invoiceAddressPostalcode.setValue(this.organisation.invoiceAddress.postalCode);
      this.form.controls.invoiceAddressHousenumber.setValue(this.organisation.invoiceAddress.houseNumber);
      this.form.controls.invoiceAddressCity.setValue(this.organisation.invoiceAddress.place);
      this.form.controls.invoiceAddressCountry.setValue(this.organisation.invoiceAddress.country);
    }

    if (this.organisation.postalAddress !== null)
    {
      this.form.controls.postalAddressStreet.setValue(this.organisation.postalAddress.street);
      this.form.controls.postalAddressState.setValue(this.organisation.postalAddress.state);
      this.form.controls.postalAddressPostalcode.setValue(this.organisation.postalAddress.postalCode);
      this.form.controls.postalAddressHousenumber.setValue(this.organisation.postalAddress.houseNumber);
      this.form.controls.postalAddressCity.setValue(this.organisation.postalAddress.place);
      this.form.controls.postalAddressCountry.setValue(this.organisation.postalAddress.country);
    }
  }

  loadOrganisation() {
    this.httpService.get(this.id).subscribe((success) => {
      this.organisation = success.data;
      this.setForm();
      this.httpContactService.get(this.organisation.id, this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
        this.contacts = success;
      });
    }, (error) => {
      if (error.status === 404)
      {
        this.router.navigate([ '/organisation' ]);
      } });
  }
}
