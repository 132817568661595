export class Address {
    name: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    place: string;
    state: string;
    mailbox: string;
    country: string;
}
