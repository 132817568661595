import { Component, OnInit, HostListener } from '@angular/core';
import { ConfirmationDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  showMenu = false;
  wasInside = false;
  currentClick = false;

  constructor(private authService: AuthService, public matDialog: MatDialog, private router: Router) { }

  ngOnInit() {
  }

  navigateToProfile() {
    this.router.navigate([ '/profile' ]);
    this.showMenu = false;
  }

  toggle() {
    this.showMenu = !this.showMenu;
    this.currentClick = true;
  }

  @HostListener('click')
  clickInside() {
    if (!this.showMenu) {
      return;
    }

    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.showMenu || this.currentClick) {
      this.currentClick = false;
      return;
    }

    if (!this.wasInside) {
      this.showMenu = false;
    }
    this.wasInside = false;
  }

  logout() {
    this.showMenu = false;
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Uitloggen',
        message: 'Weet u zeker dat u wilt uitloggen?'
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.authService.logout();
      }
    });
  }
}
