import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { Response } from 'src/app/models/Response';
import { Profile } from '../models/profile';
import { HttpClient } from '@angular/common/http';
import { TokenCreationResponse } from '../models/token-creation-request';
import { EditProfileRequest } from '../models/edit-profile-request';
import { TokenEnabledResponse } from '../models/token-enabled-response';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  getProfile(): Observable<ObjectResponse<Profile>> {
    return this.httpClient.get<ObjectResponse<Profile>>('/api/v1/Profile');
  }

  generateToken(): Observable<ObjectResponse<TokenCreationResponse>> {
    return this.httpClient.post<ObjectResponse<TokenCreationResponse>>('/api/v1/Profile/generate-token', null);
  }

  enableToken(token: string): Observable<ObjectResponse<TokenEnabledResponse>> {
    return this.httpClient.post<ObjectResponse<TokenEnabledResponse>>('/api/v1/Profile/enable-token', { Token: token });
  }

  disableToken(): Observable<ObjectResponse<TokenCreationResponse>> {
    return this.httpClient.post<ObjectResponse<TokenCreationResponse>>('/api/v1/Profile/disable-token', null);
  }

  EditProfile(request: EditProfileRequest): Observable<Response> {
    return this.httpClient.post<Response>('/api/v1/Profile/edit-profile', request);
  }
}
