<div class="card">
  <div class="card-header">
    {{'pages.auth.profile.faTitle' | translate}}
  </div>
  <div class="card-body" *ngIf="faEnabled">

    <div class="enter_code" *ngIf="tokenEnabledResponse !== undefined">
        <strong>{{'pages.auth.profile.recoveryCodes' | translate}}</strong><br />
        {{'pages.auth.profile.recoveryCodesDescription' | translate}}
        <ul>
          <li *ngFor="let code of tokenEnabledResponse.recoveryCodes">{{code}}</li>
        </ul>

        <div style="clear: both;"></div>
    </div>
    {{'pages.auth.profile.faAlreadyEnabled' | translate}}<br />
    <br />

    <button class="btn btn-primary" (click)="disable()">{{'pages.auth.profile.faDisableFa' | translate}}</button>
  </div>

  <div class="card-body" *ngIf="!faEnabled && faStep == 0">
    {{'pages.auth.profile.faDisabled' | translate}}<br /><br />
    <button class="btn btn-success" (click)="enable()">{{'pages.auth.profile.faEnableFa' | translate}}</button>
  </div>


  <div class="card-body" *ngIf="!faEnabled && faStep == 1">
    <div class="alert alert-danger" *ngIf="codeIncorrect">
      <strong>{{'global.error' | translate}}:</strong><br />{{'pages.auth.profile.faInvalidCode' | translate}}
    </div>
    {{'pages.auth.profile.faCode' | translate: { code: tokenCreationResponse.code } }}<br />
    <br />
    <qrcode [qrdata]="'otpauth://totp/' + tokenCreationResponse.account + '?secret=' + tokenCreationResponse.code + '&issuer=DuoCRM'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    <br />
    <div class="enter_code">
        <div class="fas fa-mobile-alt icon"></div>
        <div class="input">
          {{'pages.auth.profile.faFillCode' | translate}}:<br />
            <input type="text" [formControl]="authCode" class="form-control">
        </div>
        <div style="clear: both;"></div>
    </div>
    <br />
    <button class="btn" (click)="cancel()" style="float: left;">{{'pages.auth.profile.faBack' | translate}}</button>
    <button class="btn btn-success" (click)="entercode()" style="float: right;">{{'pages.auth.profile.faEnable' | translate}}</button>
  </div>
</div>
