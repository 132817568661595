import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InplaceInputComponent } from 'src/app/shared/components/inplace-input/inplace-input.component';
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { Invoice } from '../../models/invoice';
import { InvoiceHttpService } from '../../services/invoice-http.service';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceRule } from '../../models/invoice-rule';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

  id: number;
  invoice: Invoice;

  form = new FormGroup({
    invoiceNumber: new FormControl('', [ Validators.required ]),
    references: new FormControl('', [ Validators.required ]),
    date: new FormControl('', [ Validators.required ]),
    rules: new FormArray([
    ])
  });
  

  constructor(private httpService: InvoiceHttpService,
    private route: ActivatedRoute,
    public snackBar: Snackbar,
    public matDialog: MatDialog,
    private router: Router) { }

    ngOnInit() {
      this.route.params.subscribe((data) => {
        this.id = data.id;
  
        this.load();
      });
    }

    load() {
      this.httpService.get(this.id).subscribe((success) => {
        this.invoice = success.data;
        this.setForm();
      }, (error) => {
        if (error.status === 404)
        {
          this.router.navigate([ '/invoice' ]);
        } });
    }


  setForm() {
    this.form.controls.invoiceNumber.setValue(this.invoice.invoiceNumber);
    this.form.controls.references.setValue(this.invoice.references);
    
    var datePipe = new DatePipe('en-US');

    this.form.controls.date.setValue(datePipe.transform(this.invoice.date, "YYYY-MM-dd"));

    this.invoice.rules.forEach(element => {
      this.addRuleByRule(element);
    })
  }

  getControls() {
    return (this.form.get('rules') as FormArray).controls;
  }

  addRule()
  {
    console.log(this.form.get('rules'));
    const test = (this.form.get('rules') as FormArray);
    test.push(new FormGroup({
      invoiceText: new FormControl('', [ Validators.required ]),
      invoiceAmount: new FormControl('0', [ Validators.required ]),
      invoicePrice : new FormControl('0', [ Validators.required ]),
      invoiceVat : new FormControl('21', [ Validators.required ]),
      invoiceRuleId : new FormControl('0')
    }));
  }

  sample()
  {
    this.httpService.getSample(this.id).subscribe((response) => {

      let file = new Blob([response], { type: 'application/pdf' });            
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });

    return false;
  }

  addRuleInplace(component: InplaceInputComponent)
  {
    const test = (this.form.get('rules') as FormArray);
    test.push(new FormGroup({
      invoiceText: new FormControl('', [ Validators.required ]),
      invoiceAmount: new FormControl('0', [ Validators.required ]),
      invoicePrice : new FormControl('0', [ Validators.required ]),
      invoiceVat : new FormControl('21', [ Validators.required ]),
      invoiceRuleId : new FormControl('0')
    }));
  }

  addRuleByRule(rule: InvoiceRule)
  {
    const test = (this.form.get('rules') as FormArray);
    test.push(new FormGroup({
      invoiceText: new FormControl(rule.description, [ Validators.required ]),
      invoiceAmount: new FormControl(rule.amount, [ Validators.required ]),
      invoicePrice : new FormControl(rule.price, [ Validators.required ]),
      invoiceVat : new FormControl(rule.vat, [ Validators.required ]),
      invoiceRuleId : new FormControl(rule.id)
    }));
  }


  calculateLineTotal(group: FormGroup, addVat = false)
  {
    let price = +group.controls.invoicePrice.value * +group.controls.invoiceAmount.value;

    if(addVat)
    {
      price *= 1 + (+group.controls.invoiceVat.value / 100);
    }

    return price;
  }

  calculateVatLineTotal(group: FormGroup)
  {
    let price = +group.controls.invoicePrice.value * +group.controls.invoiceAmount.value * (+group.controls.invoiceVat.value / 100);

    return price;
  }

  calcuateSubTotal()
  {
    const test = (this.form.get('rules') as FormArray);

    let total = 0;
    test.controls.forEach((value, index) => {
      total += this.calculateLineTotal(value as FormGroup);
    });

    return total;
  }

  calculateVAT() 
  {
    const test = (this.form.get('rules') as FormArray);

    let total = 0;
    test.controls.forEach((value, index) => {
      total += this.calculateVatLineTotal(value as FormGroup);
    });

    return total;
  }

  calculateTotal()
  {
    const test = (this.form.get('rules') as FormArray);

    let total = 0;
    test.controls.forEach((value, index) => {
      total += this.calculateLineTotal(value as FormGroup, true);
    });

    return total;
  }

  remove(i: number)
  {
    const test = (this.form.get('rules') as FormArray);
    test.removeAt(i);

    this.form.markAsTouched();
    this.form.markAsDirty();

    this.save();
  }

  save() {
    this.saveForm();
  }

  
  saveForm() {
    if (this.form.dirty && this.form.valid) {
      const origObject = new Invoice();
      origObject.id = this.id;
      origObject.references = this.form.controls.references.value;
      origObject.invoiceNumber = this.form.controls.invoiceNumber.value;
      origObject.date = this.form.controls.date.value;
      origObject.contact = this.invoice.contact;

      var formRules = this.form.get('rules') as FormArray;

      origObject.rules = [];

      for (let control of formRules.controls) {

        var group = control as FormGroup;

        var rule = new InvoiceRule();
        rule.amount = +group.controls.invoiceAmount.value;
        rule.description = group.controls.invoiceText.value;
        rule.price = +group.controls.invoicePrice.value;
        rule.vat = +group.controls.invoiceVat.value;
        rule.id = +group.controls.invoiceRuleId.value;
        origObject.rules.push(rule);
      }

      console.log(origObject);
      this.invoice = origObject;
      this.form.markAsPristine();

      this.httpService.put(this.id, origObject).subscribe((success) => {
        this.snackBar.openSuccess('pages.invoice.detail.saved');
      });
    }
  }
}
