import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { ResourceHttpService } from '../../services/resource-http.service';
import { Resource } from '../../models/resource';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-resource-dialog',
  templateUrl: './create-resource-dialog.component.html',
  styleUrls: ['./create-resource-dialog.component.scss']
})
export class CreateResourceDialogComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', [ Validators.required]),
    maxUserCount: new FormControl('', [ Validators.required, Validators.min(1), Validators.max(200) ]),
    customerNumber: new FormControl('', [ Validators.min(1) ])
  });

  response: ObjectResponse<Resource>;

  constructor(public dialogRef: MatDialogRef<CreateResourceDialogComponent>,
              private resourceHttpService: ResourceHttpService,
              private router: Router) {}

  ngOnInit() {
  }

  mapResponse() {
    if (this.response === undefined) {
      return;
    }

    this.response.errors.forEach((message) => {

      const error = {};
      error[message.code] = true;

      this.form.controls[message.field].setErrors(error);
    });
  }

  submit() {
    const resource = new Resource();
    resource.name = this.form.controls.name.value;
    resource.customerNumber = this.form.controls.customerNumber.value !== '' ?  +this.form.controls.customerNumber.value : null;
    resource.maxUserCount = this.form.controls.maxUserCount.value;

    this.resourceHttpService.create(resource).subscribe((success) => {

      this.dialogRef.close({ finished: true, id: success.data.id });
      this.router.navigate([`resource/${success.data.id}`]);
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });

  }

  close() {
    this.dialogRef.close({ finished: false });
  }

}
