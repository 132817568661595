import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  Resource
} from '../../models/resource';
import {
  ResourceUserHttpService
} from '../../services/resource-user-http.service';
import {
  Response, ResponseMessage
} from 'src/app/models/Response';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  response: Response;
  genericErrors: ResponseMessage[];

  constructor(public dialogRef: MatDialogRef < InviteUserDialogComponent > ,
              @Inject(MAT_DIALOG_DATA) public resource: Resource,
              private userHttpService: ResourceUserHttpService) {}

  ngOnInit() {}

  mapResponse() {
    if (!this.response) {
      return;
    }

    this.genericErrors = [];

    this.response.errors.forEach((message) => {

      if (message.field === '') {
        this.genericErrors.push(message);
      } else {
        const error = {};
        error[message.code] = true;

        this.form.controls[message.field].setErrors(error);
      }
    });
  }


  submit() {
    if (this.form.invalid) {
      return;
    }

    this.userHttpService.inviteUser(this.resource.id, this.form.controls.email.value).subscribe((success) => {
      this.dialogRef.close({
        finished: true
      });
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });
  }

  close() {
    this.dialogRef.close({
      finished: false
    });
  }
}
