<section class="header">
    <div class="row">
        <div class="col-md-12">

            <h1 style="float: left;">{{'pages.invoice.detail.pageTitle' | translate: { name: invoice.references + " (#"
                + invoice.invoiceNumber + ")" } }}</h1>
            <div style="float: right"><button class="btn btn-success" (click)="sample()">Maak factuur</button></div>
        </div>
    </div>
</section>

<section class="content">

    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        Factuur
                    </div>
                    <div class="card-body">
                        <table class="inplace" style="word-break: break-all;">
                            <tr>
                                <td>{{ 'fields.references' | translate }}:</td>
                                <td>
                                    <app-inplace-input [controlName]='"references"' (focusOut)='save()' [type]='"text"'>
                                    </app-inplace-input>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'fields.invoiceNumber' | translate }}:</td>
                                <td>
                                    <app-inplace-input [controlName]='"invoiceNumber"' (focusOut)='save()'
                                        [type]='"text"'>
                                    </app-inplace-input>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'fields.invoiceDate' | translate }}:</td>
                                <td>
                                    <app-inplace-input [controlName]='"date"' (focusOut)='save()' [type]='"date"'
                                        [format]='"date"'>
                                    </app-inplace-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        Klant
                    </div>
                    <div class="card-body">
                        <table class="inplace" style="word-break: break-all;">
                            <strong> Naam: {{ invoice.contact.firstname }} {{ invoice.contact.lastname }} <i
                                    class="fas fa-pencil-alt edit"></i><br />Organisatie: {{
                                invoice.contact.organisationName ?? "Particulier" }}<br />
                                Telefoon: {{invoice.contact.telephone }}<br />
                                Email: {{invoice.contact.email }}<br />
                            </strong>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        Regels
                    </div>
                    <div class="card-body full" style="overflow-x: scroll;">
                        <table style="table-layout: fixed;">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <th colspan="1"></th>
                                    <th colspan="5">{{'pages.invoice.detail.rules.description' | translate}}</th>
                                    <th colspan="2">{{'pages.invoice.detail.rules.amount' | translate}}</th>
                                    <th colspan="2">{{'pages.invoice.detail.rules.price' | translate}}</th>
                                    <th colspan="2">{{'pages.invoice.detail.rules.vat' | translate}}</th>
                                    <th colspan="2">{{'pages.invoice.detail.rules.total' | translate}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody formArrayName="rules">
                                <tr *ngFor="let line of getControls(); let i=index" [formGroupName]="i">
                                    <td colspan="1">{{i + 1}}</td>
                                    <td colspan="5">
                                        <app-inplace-input [controlName]='"invoiceText"' [form]="line"
                                            (focusOut)='save()' [type]='"text"'>
                                        </app-inplace-input>
                                    </td>
                                    <td colspan="2">
                                        <app-inplace-input [controlName]='"invoiceAmount"' [form]="line"
                                            (focusOut)='save()' [type]='"number"'>
                                        </app-inplace-input>
                                    </td>
                                    <td colspan="2">
                                        <app-inplace-input [controlName]='"invoicePrice"' [form]="line"
                                            (focusOut)='save()' [type]='"number"' [format]='"currency"'
                                            [formatValue]='"EUR"'>
                                        </app-inplace-input>
                                    </td>
                                    <td colspan="2">
                                        <app-inplace-input [controlName]='"invoiceVat"' [form]="line"
                                            (focusOut)='save()' [type]='"number"' [formatValue]='"%"'
                                            (lastTabAction)="addRuleInplace($event)">
                                        </app-inplace-input>
                                    </td>
                                    <td colspan="2">{{calculateLineTotal(line) | currency:'EUR'}}</td>
                                    <td><i (click)="remove(i)" class="fas fa-trash-alt edit"></i></td>
                                </tr>
                            </tbody>
                        </table>

                        <table style="table-layout: fixed;">
                            <tr>
                                <td colspan="5">
                                    <a (click)="addRule()" class="edit">{{'pages.invoice.detail.addRule' |
                                        translate}}</a>
                                </td>
                                <td colspan="4">
                                    <table style="table-layout: fixed;">
                                        <tr>
                                            <td><strong>{{'pages.invoice.detail.rules.subtotal' | translate}}:</strong>
                                            </td>
                                            <td>{{calcuateSubTotal() | currency:'EUR'}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{'pages.invoice.detail.rules.vat' | translate}}:</strong></td>
                                            <td>{{calculateVAT() | currency:'EUR'}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{'pages.invoice.detail.rules.total' | translate}}:</strong>
                                            </td>
                                            <td>{{calculateTotal() | currency:'EUR'}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </form>
</section>