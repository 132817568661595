<section class="header">
  <div class="row">
    <div class="col-md-12">
      <h1>{{'pages.dashboard.pageTitle' | translate}}</h1>
    </div>
  </div>
</section>

<section class="content">

  <div class="row">
    <div class="col-md-6">
  
      <div class="card">
        <div class="card-header">
          {{'pages.dashboard.pageTitle' | translate}}
        </div>
        <div class="card-body">
          Welkom bij het coolste CRM ever :D
        </div>
      </div>
    </div>
  </div>
  
</section>