<select #control class='cellInput' (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()'
[formControl]="formControl" (keydown.enter)='$event.target.blur()'>
<option *ngIf='empty'></option>
<option *ngFor='let value of values' value="{{value}}">{{ 'select.' + value | translate }}</option>
</select>
<div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)" class="control-error">
  <br>
  <ul>
    <li *ngFor="let error of formControl.errors | objToKeys">
      {{ errorPrefix + error | translate }}
    </li>
  </ul>
</div>