<section class="header">
  <div class="row">
    <div class="col-md-12">
      <h1>{{'pages.organisation.detail.pageTitle' | translate: { name: form.controls.name.value } }}</h1>
    </div>
  </div>
</section>


<form [formGroup]="form">
  <div class="row">
    <div class="col-md-12 col-xl-4">

      <div class="card">
        <div class="card-header">
          {{'pages.organisation.detail.infoTitle' | translate}}
        </div>
        <div class="card-body">
          <h2>
            <app-inplace-input [controlName]='"name"' (focusOut)='saveForm()' [type]='"text"'></app-inplace-input>
          </h2>
          <hr>
          <table class="inplace" style="word-break: break-all;">
            <tr>
              <td>{{ 'fields.type' | translate }}:</td>
              <td>
                <app-inplace-select [controlName]='"type"' (focusOut)='saveForm()' [empty]="true" [values]="this.types">
                </app-inplace-select>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.customerNumber' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"customerNumber"' (focusOut)='saveForm()' [type]='"text"'>
                </app-inplace-input>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.email' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"email"' (focusOut)='saveForm()' [type]='"text"'></app-inplace-input>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.telephone' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"telephone"' (focusOut)='saveForm()' [type]='"text"'>
                </app-inplace-input>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.website' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"website"' (focusOut)='saveForm()' [type]='"text"'>
                </app-inplace-input>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.cocnumber' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"cocnumber"' (focusOut)='saveForm()' [type]='"text"'>
                </app-inplace-input>
              </td>
            </tr>
            <tr>
              <td>{{ 'fields.vatnumber' | translate }}:</td>
              <td>
                <app-inplace-input [controlName]='"vatnumber"' (focusOut)='saveForm()' [type]='"text"'>
                </app-inplace-input>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-xl-4">

      <div class="card">
        <div class="card-header">
          {{ 'pages.organisation.detail.addressTitle'| translate }}
        </div>
        <div class="card-body full">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="{{ 'pages.organisation.detail.visitAddressTitle'| translate }}">
              <table class="inplace" style="word-break: break-all;">
                <tr>
                  <td>{{ 'fields.address.street' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressStreet"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.houseNumber' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressHousenumber"' (focusOut)='saveForm()'
                      [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.postalcode' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressPostalcode"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.city' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressCity"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.state' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressState"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.country' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"visitAddressCountry"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
              </table>
            </mat-tab>
            <mat-tab label="{{ 'pages.organisation.detail.invoiceAddressTitle'| translate }}">
              <table class="inplace" style="word-break: break-all;">
                <tr>
                  <td>{{ 'fields.address.street' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressStreet"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.houseNumber' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressHousenumber"' (focusOut)='saveForm()'
                      [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.postalcode' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressPostalcode"' (focusOut)='saveForm()'
                      [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.city' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressCity"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.state' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressState"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.country' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"invoiceAddressCountry"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
              </table>
            </mat-tab>
            <mat-tab label="{{ 'pages.organisation.detail.postalAddressTitle'| translate }}">
              <table class="inplace" style="word-break: break-all;">
                <tr>
                  <td>{{ 'fields.address.street' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressStreet"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.houseNumber' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressHousenumber"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.postalcode' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressPostalcode"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.city' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressCity"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.state' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressState"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.address.country' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"postalAddressCountry"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
              </table>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

    </div>


    <div class="col-md-12 col-xl-4" *ngIf="contacts !== undefined">

      <div class="card">
        <div class="card-header">
          <i class="right-button fas fa-plus" (click)="createContact()"></i>
          {{ 'pages.organisation.detail.contactTitle'| translate }}
        </div>
        <div class="card-body full">
          <table>
            <thead>
              <th>{{ 'fields.name'| translate }}</th>
              <th>{{ 'fields.email'| translate }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr class="clickable"  *ngFor="let contact of contacts.data" [routerLink]="[ '/contact', contact.id ]">
                <td>{{ contact.firstname }} {{ contact.lastname }}</td>
                <td>
                  {{contact.email }}
                </td>
                <td>
                  <i class="view far fa-eye"></i>
                </td>
              </tr>

              <tr class="paging">
                <td colspan="3">
                  <div class="col-md-12 col-lg-12 full">
                    <app-pagination #paging [currentOffset]="pageNum * pageSize" [total]="contacts.total" [pageSize]="pageSize"  (onSelect)="navigate($event)"></app-pagination>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


</form>
<div class="row">
  <div class="col-md-12 col-xl-12">

    <div class="card">
      <div class="card-body">

      </div>
    </div>
  </div>

</div>
