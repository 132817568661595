import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Organisation } from '../models/organisation';
import { ListResponse } from 'src/app/models/list-response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';

@Injectable({
  providedIn: 'root',
})
export class OrganisationHttpService {
  constructor(private httpClient: HttpClient) {}

  get(id: number): Observable<ObjectResponse<Organisation>> {
    return this.httpClient.get<ObjectResponse<Organisation>>(`/api/v1/organisation/${id}`);
  }


  put(id: number, organisation: Organisation): Observable<ObjectResponse<Organisation>> {
    return this.httpClient.patch<ObjectResponse<Organisation>>(`/api/v1/organisation/${id}`, organisation);
  }

  listOrganisation(take: number, skip: number, name: string = null): Observable<ListResponse<Organisation>> {
    let url = `/api/v1/organisation?take=${take}&skip=${skip}`;

    if(name != null)
    {
      	url += `&name=${name}`;
    }
    return this.httpClient.get<ListResponse<Organisation>>(url);
  }

  create(name: string, customerNumber: string): Observable<ObjectResponse<Organisation>> {
    const organisation = new Organisation();
    organisation.name = name;
    organisation.customerCode = customerNumber;

    return this.httpClient.post<ObjectResponse<Organisation>>(`/api/v1/organisation`, organisation);
  }
}
