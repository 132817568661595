import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../models/profile';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { EditProfileRequest } from '../../models/edit-profile-request';
import { Response } from '../../../../models/Response';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile: Profile;
  error: Response;
  success: boolean;

  form: FormGroup;

  constructor(private profileService: ProfileService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      NewPassword: new FormControl(''),
      NewPasswordConf: new FormControl('', ),
      CurrentPassword: new FormControl('', Validators.required)
    });
    this.form.setValidators(this.comparisonValidator());
   }

  ngOnInit() {
    this.profileService.getProfile().subscribe((success) => {
      this.profile = success.data;
      this.form.controls.FirstName.setValue(this.profile.firstName);
      this.form.controls.LastName.setValue(this.profile.lastName);
    }, (error) => {

    });
  }

  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls.NewPassword;
      const control2 = group.controls.NewPasswordConf;
      if (control1.value !== '' && control1.value !== control2.value) {
         control2.setErrors({notsame: true});
      } else {
         control2.setErrors(null);
      }
      return;
    };
  }

  mapResponse() {
    if (!this.error) {
      return;
    }

    this.error.errors.forEach((message) => {

      const error = {};
      error[message.code] = true;

      this.form.controls[message.field].setErrors(error);
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const request = new EditProfileRequest();
    request.firstName = this.form.value.FirstName;
    request.lastName = this.form.value.LastName;
    request.newPassword = this.form.value.NewPassword;
    request.currentPassword = this.form.value.CurrentPassword;

    this.profileService.EditProfile(request).subscribe((success) => {
      this.form.controls.NewPassword.reset();
      this.form.controls.CurrentPassword.reset();
      this.form.controls.NewPasswordConf.reset();

      this.error = null;
      this.success = true;
    }, (error) => {
      this.error = error.error;
      this.mapResponse();
      this.success = false;
    });
  }
}
