<div *ngIf="!loading">
  <p><strong>{{'pages.auth.forgot-password.reset.pageInfo' | translate}}</strong></p>

  <div *ngIf="!found">
    <div class="alert alert-danger">
      <strong>{{'global.error' | translate}}:</strong><br />
      {{'pages.auth.forgot-password.reset.invalidLink' | translate}}
    </div>
  </div>

  <div *ngIf="found">

    <div class="alert alert-danger" *ngIf="error !== undefined && error !== null">
      <strong>{{'global.error' | translate}}:</strong><br>
      {{'global.invalidInput' | translate}}
    </div>

  <div class="alert alert-success" *ngIf="success && submitted">
    <strong>{{'global.success' | translate}}</strong><br>{{'pages.auth.forgot-password.reset.success' | translate}}
  </div>

    <form [formGroup]="form" (ngSubmit)="reset()">
      <div class="form-group" *ngIf="!success">
        <label>{{'fields.email' | translate}}:</label>
        <input type="email" class="form-control full-width" value="{{email}}" disabled />
      </div>
      <div class="form-group" *ngIf="!success">
        <label>{{'fields.password' | translate}}:</label>
        <input type="password" class="form-control full-width" formControlName="password" />
        <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['password'].errors | objToKeys">
              {{ 'errors.password.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group" *ngIf="!success">
        <label>{{'fields.passwordConf' | translate}}:</label>
        <input type="password" formControlName="passwordConf" class="form-control" />
        <div *ngIf="form.controls['passwordConf'].invalid && (form.controls['passwordConf'].dirty || form.controls['passwordConf'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['passwordConf'].errors | objToKeys">
              {{ 'errors.passwordConf.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-full-width" [disabled]="form.invalid"  *ngIf="!success">{{'pages.auth.forgot-password.reset.submit' | translate}}</button><br />
      </div>
    </form>
  </div>
  <a href="/auth/login">{{'pages.auth.back' | translate}}</a>
</div>
