<div *ngIf='!editMode && this.type != "select" && this.format == "none"' (click)='enableEditMode()' class='cell inplace-input'>{{ formControl.value }} {{ formatValue }}</div>
<div *ngIf='!editMode && this.type != "select" && this.format == "currency"' (click)='enableEditMode()' class='cell inplace-input'>{{ +formControl.value | currency:formatValue }}</div>
<div *ngIf='!editMode && this.type != "select" && this.format == "date"' (click)='enableEditMode()' class='cell inplace-input'>{{ formControl.value | date }}</div>

<div *ngIf='editMode' class='inplace-input inplace-active'>

  <input #control (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()'
  (keydown.Tab)="tab($event)"
    class='cellInput' [type]='this.type' [formControl]="formControl" (keydown.enter)='$event.target.blur()'
    *ngIf='this.type != "select"'>
  <div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)" class="control-error">
    <br>
    <ul>
      <li *ngFor="let error of formControl.errors | objToKeys">
        {{ errorPrefix + error | translate }}
      </li>
    </ul>
  </div>

</div>
