<div id="sidebar" [class.active]="active">

  <div class="sidebar-logo">
    <h1><i class="far fa-balance-scale-right"></i></h1>
  </div>


  <ul class="menu">

    <li class="heading"  [routerLink]="[ '/' ]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
      <div class="logo red">
        <i class="fas fa-tachometer-alt"></i>
      </div>
      <div class="text">
        {{ 'menu.dashboard' | translate}}
      </div>
    </li>


    <ng-container *ngIf="isEmployee">

      <li class="heading"  [routerLink]="[ '/organisation' ]" [routerLinkActive]="['active']">
        <div class="logo red">
          <i class="fas fa-city"></i>
        </div>
        <div class="text">
          {{'menu.organisations' | translate}}
        </div>
      </li>

      <li class="heading"  [routerLink]="[ '/contact' ]" [routerLinkActive]="['active']">
        <div class="logo red">
          <i class="far fa-address-book"></i>
        </div>
        <div class="text">
          {{'menu.contacts' | translate}}
        </div>
      </li>
      
      <li class="heading"  [routerLink]="[ '/invoice' ]" [routerLinkActive]="['active']">
        <div class="logo red">
          <i class="fas fa-file-invoice-dollar"></i>
        </div>
        <div class="text">
          {{'menu.invoice' | translate}}
        </div>
      </li>
      
      <li class="heading"  [routerLink]="[ '/my-organisation' ]" [routerLinkActive]="['active']">
        <div class="logo red">
          <i class="fas fa-city"></i>
        </div>
        <div class="text">
          {{'menu.my-organization' | translate}}
        </div>
      </li>

      <li class="heading"  [routerLink]="[ '/employee' ]" [routerLinkActive]="['active']">
        <div class="logo red">
          <i class="fas fa-user"></i>
        </div>
        <div class="text">
          {{'menu.employees' | translate}}
        </div>
      </li>
    </ng-container>

      <ng-container *ngIf="isGlobalAdmin">
        <li class="heading"  [routerLink]="[ '/resource' ]" [routerLinkActive]="['active']">
          <div class="logo">
            <i class="fas fa-hashtag"></i>
          </div>
          <div class="text">
            {{'menu.resources' | translate}}
          </div>
        </li>
      </ng-container>
  </ul>
</div>