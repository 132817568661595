import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseMessage } from 'src/app/models/Response';
import { MatDialogRef } from '@angular/material/dialog';
import { Organisation } from '../../models/organisation';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { OrganisationHttpService } from '../../services/organisation-http.service';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(70)]),
    customerNumber: new FormControl('', [Validators.maxLength(20)])
  });

  response: ObjectResponse<Organisation>;
  genericErrors: ResponseMessage[];

  constructor(public dialogRef: MatDialogRef < CreateDialogComponent >,
              private organisationHttpService: OrganisationHttpService) {}

  ngOnInit(): void {
  }

  mapResponse() {
    if (!this.response) {
      return;
    }

    this.genericErrors = [];

    this.response.errors.forEach((message) => {

      if (message.field === '') {
        this.genericErrors.push(message);
      } else {
        const error = {};
        error[message.code] = true;

        this.form.controls[message.field].setErrors(error);
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.invalid) {
      return;
    }

    this.organisationHttpService.create(
      this.form.controls.name.value,
      this.form.controls.customerNumber.value
    ).subscribe((success) => {
      this.dialogRef.close({
        finished: true,
        id: success.data.id
      });
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });
  }

  close() {
    this.dialogRef.close({
      finished: false
    });
  }

}
