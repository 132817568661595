import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ResponseMessage } from 'src/app/models/Response';
import { Invoice } from '../../models/invoice';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { MatDialogRef } from '@angular/material/dialog';
import { InvoiceHttpService } from '../../services/invoice-http.service';

@Component({
  selector: 'app-create-invoice-dialog',
  templateUrl: './create-invoice-dialog.component.html',
  styleUrls: ['./create-invoice-dialog.component.scss']
})
export class CreateInvoiceDialogComponent implements OnInit {

  form = new FormGroup({
    reference: new FormControl('', [Validators.required, Validators.maxLength(70)]),
    number: new FormControl('', [Validators.required]),
    contactId: new FormControl('', [Validators.required]),
  });

  response: ObjectResponse<Invoice>;
  genericErrors: ResponseMessage[];

  constructor(public dialogRef: MatDialogRef < CreateInvoiceDialogComponent >,
              private serviceService: InvoiceHttpService) {}

  ngOnInit(): void {
  }

  mapResponse() {
    if (!this.response) {
      return;
    }

    this.genericErrors = [];

    this.response.errors.forEach((message) => {

      if (message.field === '') {
        this.genericErrors.push(message);
      } else {
        const error = {};
        error[message.code] = true;

        this.form.controls[message.field].setErrors(error);
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.invalid) {
      return;
    }

    this.serviceService.create(
      this.form.controls.reference.value,
      +this.form.controls.contactId.value,
      +this.form.controls.number.value
    ).subscribe((success) => {
      this.dialogRef.close({
        finished: true,
        id: success.data.id
      });
    }, (error) => {
      this.response = error.error;
      this.mapResponse();
    });
  }


  close() {
    this.dialogRef.close();
  }
}
