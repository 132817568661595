import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateResourceDialogComponent } from '../../dialogs/create-resource-dialog/create-resource-dialog.component';
import { Router } from '@angular/router';
import { ResourceHttpService } from '../../services/resource-http.service';
import { Resource } from '../../models/resource';
import { ListResponse } from 'src/app/models/list-response';

@Component({
  selector: 'app-resource',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent implements OnInit {

  response: ListResponse<Resource>;

  constructor(public matDialog: MatDialog, private router: Router, private resourceService: ResourceHttpService) { }

  ngOnInit() {
    this.resourceService.listResource(100, 0).subscribe((success) => {
      this.response = success;
    }, (error) => {
      // We should error here
    });
  }

  create() {
    this.matDialog.open(CreateResourceDialogComponent).afterClosed().subscribe(result => {
      if (result.finished) {
        this.router.navigateByUrl(`resource/${result.id}`);
      }
    });
  }
}
