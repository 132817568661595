import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ListResponse } from 'src/app/models/list-response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { OrganisationContact } from '../models/organisation-contact';

@Injectable({
  providedIn: 'root',
})
export class OrganisationContactHttpService {
  constructor(private httpClient: HttpClient) {}

  get(organisationId: number, take: number, skip: number): Observable<ListResponse<OrganisationContact>> {
    return this.httpClient.get<ListResponse<OrganisationContact>>
    (`/api/v1/organisation/${organisationId}/contact?skip=${skip}&take=${take}`);
  }

  post(organisationId: number, contact: OrganisationContact): Observable<ObjectResponse<OrganisationContact>> {
    return this.httpClient.post<ObjectResponse<OrganisationContact>>(`/api/v1/organisation/${organisationId}/contact`, contact);
  }
}
