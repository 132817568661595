import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateDialogComponent } from '../../dialogs/create-dialog/create-dialog.component';
import { OrganisationHttpService } from '../../services/organisation-http.service';
import { Organisation } from '../../models/organisation';
import { ListResponse } from 'src/app/models/list-response';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit {
  pageSize = 10;

  response: ListResponse<Organisation>;
  pageNum: number;

  @ViewChild('paging') paging;

  constructor(public matDialog: MatDialog,
              private router: Router,
              private organisationHttpService: OrganisationHttpService,
              private route: ActivatedRoute) {
                this.route.queryParams.subscribe(params => {
                    this.pageNum = +params.page || 0;
                });
               }

  ngOnInit() {
    this.organisationHttpService.listOrganisation(this.pageSize, this.pageNum * 2).subscribe((success) => {
      this.response = success;
    }, (error) => {
      // We should error here
    });
  }

  create() {
    this.matDialog.open(CreateDialogComponent, {
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.router.navigate([ 'organisation', result.id ]);
      }
    });
  }



  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.organisationHttpService.listOrganisation(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.response = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
    }, (error) => {
      // We should error here
    });
  }
}
