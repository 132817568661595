<div class="card">
  <div class="card-header">
    {{'pages.resource.create-dialog.title' | translate}}
  </div>
  <div class="card-body">
<!-- 
    <div class="alert alert-danger" *ngIf="error !== undefined">
      <strong>{{'global.error' | translate}}:</strong><br>
      {{'global.invalidInput' | translate}}
    </div> -->
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-group">
        <label>{{'fields.name' | translate}}:</label>
        <input type="text" formControlName="name" class="form-control big" />
        <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['name'].errors | objToKeys">
              {{ 'errors.name.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label>{{'fields.maxUserCount' | translate}}:</label>
        <input type="number" formControlName="maxUserCount"  class="form-control"/>
        <div *ngIf="form.controls['maxUserCount'].invalid && (form.controls['maxUserCount'].dirty || form.controls['maxUserCount'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['maxUserCount'].errors | objToKeys">
              {{ 'errors.maxUserCount.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label>{{'fields.customerNumber' | translate}}:</label>
        <input type="number" formControlName="customerNumber"  class="form-control"/>
        <div *ngIf="form.controls['customerNumber'].invalid && (form.controls['customerNumber'].dirty || form.controls['customerNumber'].touched)" class="control-error">          
          <ul>
            <li *ngFor="let error of form.controls['customerNumber'].errors | objToKeys">
              {{ 'errors.customerNumber.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      
      <div class="form-group">
        <button class="btn btn-primary" [disabled]="form.invalid">{{'pages.resource.create-dialog.submit' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger" type="button" (click)="close()">{{'global.close' | translate}}</button>
      </div>
    </form>
  </div>
</div>