import { Address } from '../../organisation/models/address';

export class MyOrganisation {
    email: String;
    organisationName: String;
    telephone: String;
    chamberOfCommerce: String;
    vatNumber: String;

    address: Address;
}
