
<form [formGroup]="form" (ngSubmit)="submit()">

<section class="header">
  <div class="row">
    <div class="col-md-12">
        <h1 class="float-left">{{'pages.my-organisation.pageTitle' | translate}}</h1>
        <div class="float-right">
          <button type="submit" class="btn btn-primary btn-full-width btn-success" [disabled]="form.invalid">{{'pages.my-organisation.save' | translate}}</button>
        </div>
    </div>
  </div>
</section>

<section class="content">
  
<div class="row" >
    <div class="col-md-12 col-xl-6">

    <div class="card">
      <div class="card-header">
        {{'pages.my-organisation.generalInfo' | translate}}
      </div>
      <div class="card-body">
          <div class="alert alert-success" *ngIf="success">
            <strong>{{'global.success' | translate}}:</strong><br>{{'pages.my-organisation.organisationSaved' | translate}}
          </div>
          <div class="alert alert-danger" *ngIf="error !== undefined && error !== null">
            <strong>{{'global.error' | translate}}:</strong><br>
            {{'global.invalidInput' | translate}}
          </div>
            <div class="form-group">
              <label>{{'fields.organisationName' | translate}}*:</label>
              <input type="text" formControlName="organisationName" class="form-control" value="test" />
              <div *ngIf="form.controls['organisationName'].invalid && (form.controls['organisationName'].dirty || form.controls['organisationName'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['organisationName'].errors | objToKeys">
                    {{ 'errors.organisationName.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.email' | translate}}*:</label>
              <input type="text" formControlName="email" class="form-control" value="test" />
              <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['email'].errors | objToKeys">
                    {{ 'errors.email.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.telephone' | translate}}:</label>
              <input type="text" formControlName="telephone" class="form-control" value="test" />
              <div *ngIf="form.controls['telephone'].invalid && (form.controls['telephone'].dirty || form.controls['telephone'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['telephone'].errors | objToKeys">
                    {{ 'errors.telephone.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.vatnumber' | translate}}:</label>
              <input type="text" formControlName="vatNumber" class="form-control" value="test" />
              <div *ngIf="form.controls['vatNumber'].invalid && (form.controls['vatNumber'].dirty || form.controls['vatNumber'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['vatNumber'].errors | objToKeys">
                    {{ 'errors.vatnumber.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.cocnumber' | translate}}:</label>
              <input type="text" formControlName="chamberOfCommerce" class="form-control" value="test" />
              <div *ngIf="form.controls['chamberOfCommerce'].invalid && (form.controls['chamberOfCommerce'].dirty || form.controls['chamberOfCommerce'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['chamberOfCommerce'].errors | objToKeys">
                    {{ 'errors.cocnumber.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-full-width" [disabled]="form.invalid">{{'pages.my-organisation.save' | translate}}</button>
            </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-xl-6">

    <div class="card">
      <div class="card-header">
        {{'pages.my-organisation.invoiceAdress' | translate}}
      </div>
      <div class="card-body">
          <div class="alert alert-success" *ngIf="success">
            <strong>{{'global.success' | translate}}:</strong><br>{{'pages.my-organisation.organisationSaved' | translate}}
          </div>
          <div class="alert alert-danger" *ngIf="error !== undefined && error !== null">
            <strong>{{'global.error' | translate}}:</strong><br>
            {{'global.invalidInput' | translate}}
          </div>
          <div class="form-group">
            <label>{{'fields.address.street' | translate}}:</label>
            <input type="text" formControlName="street" class="form-control" value="test" />
            <div *ngIf="form.controls['street'].invalid && (form.controls['street'].dirty || form.controls['street'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['street'].errors | objToKeys">
                  {{ 'errors.street.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>{{'fields.address.houseNumber' | translate}}:</label>
            <input type="text" formControlName="houseNumber" class="form-control" value="test" />
            <div *ngIf="form.controls['houseNumber'].invalid && (form.controls['houseNumber'].dirty || form.controls['houseNumber'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['houseNumber'].errors | objToKeys">
                  {{ 'errors.houseNumber.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>{{'fields.address.postalcode' | translate}}:</label>
            <input type="text" formControlName="postalCode" class="form-control" value="test" />
            <div *ngIf="form.controls['postalCode'].invalid && (form.controls['postalCode'].dirty || form.controls['postalCode'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['postalCode'].errors | objToKeys">
                  {{ 'errors.postalcode.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>{{'fields.address.city' | translate}}:</label>
            <input type="text" formControlName="city" class="form-control" value="test" />
            <div *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['city'].errors | objToKeys">
                  {{ 'errors.city.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>{{'fields.address.state' | translate}}:</label>
            <input type="text" formControlName="state" class="form-control" value="test" />
            <div *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['state'].errors | objToKeys">
                  {{ 'errors.state.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>{{'fields.address.country' | translate}}:</label>
            <input type="text" formControlName="country" class="form-control" value="test" />
            <div *ngIf="form.controls['country'].invalid && (form.controls['country'].dirty || form.controls['country'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['country'].errors | objToKeys">
                  {{ 'errors.country.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-full-width" [disabled]="form.invalid">{{'pages.my-organisation.save' | translate}}</button>
            </div>
      </div>
    </div>
  </div>
</div>

</section>
</form>