<section class="header">
  <div class="row">
    <div class="col-md-12">
      <h1 style="float: left;">{{'pages.organisation.list.pageTitle' | translate}}</h1>
      <div style="float: right"><button class="btn btn-success" [routerLink]=""
          (click)="create()">{{'pages.organisation.list.create' | translate}}</button></div>
    </div>
  </div>
</section>

<section class="content" *ngIf="response !== undefined">

  <div class="row">
    <div class="col-md-12 col-lg-12 full">
      <table>
        <thead>
          <th>{{ 'fields.name' | translate }}</th>
          <th>{{ 'fields.customerNumber' | translate }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr class="clickable" *ngFor="let organisation of response.data" [routerLink]="[ '/organisation', organisation.id ]">
            <td>{{ organisation.name }}</td>
            <td>
              {{ organisation.customerCode }}
            </td>
            <td>
              <i class="fas fa-pencil-alt edit" [routerLink]="[ '/organisation', organisation.id ]"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row paging">
    <div class="col-md-12 col-lg-12 full">
      <app-pagination #paging [currentOffset]="pageNum * pageSize" [total]="response.total" [pageSize]="pageSize"  (onSelect)="navigate($event)"></app-pagination>
      </div>
  </div>

</section>
