<div class="card">
    <div class="card-header">
      {{'pages.organisation.list.createTitle' | translate}}
    </div>
    <div class="card-body">
      
      <div class="alert alert-danger" *ngIf="genericErrors !== undefined">
        <strong>{{'global.error' | translate}}:</strong><br />
        <ul>
          <li *ngFor="let error of genericErrors">
            <span *ngIf="error.code == 'invalidstate'">U heeft uw limiet bereikt.</span>
          </li>
        </ul>
      </div>
  
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-group">
          <label>{{'fields.firstname' | translate}}:</label>
          <input type="text" formControlName="firstname" class="form-control big" />
          <div *ngIf="form.controls['firstname'].invalid && (form.controls['firstname'].dirty || form.controls['firstname'].touched)" class="control-error">
            <ul>
              <li *ngFor="let error of form.controls['firstname'].errors | objToKeys">
                {{ 'errors.firstname.' + error | translate }}
              </li>
            </ul>
          </div>
        </div>
        
        <div class="form-group">
            <label>{{'fields.lastname' | translate}}:</label>
            <input type="text" formControlName="lastname" class="form-control big" />
            <div *ngIf="form.controls['lastname'].invalid && (form.controls['lastname'].dirty || form.controls['lastname'].touched)" class="control-error">
              <ul>
                <li *ngFor="let error of form.controls['lastname'].errors | objToKeys">
                  {{ 'errors.lastname.' + error | translate }}
                </li>
              </ul>
            </div>
          </div>

        <div class="form-group">
          <button class="btn btn-primary" type="submit" [disabled]="form.invalid">{{'pages.organisation.list.createButton' | translate}}</button>&nbsp;&nbsp;<button type='button' class="btn btn-danger" (click)="close()">{{'global.close' | translate}}</button>
        </div>
      </form>
    </div>
  </div>