import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroupDirective, FormControl } from '@angular/forms';

@Component({
  selector: 'app-inplace-select',
  templateUrl: './inplace-select.component.html',
  styleUrls: ['./inplace-select.component.scss']
})
export class InplaceSelectComponent implements OnInit {

  data: string;
  @Input() type = 'text';
  @Input() controlName: string;
  @Input() values: string[];
  @Input() empty = false;

  @Output() focusOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  errorPrefix: string;
  public formControl: FormControl;
  @ViewChild('control') control: ElementRef;

  constructor(private parent: FormGroupDirective) {}

  ngOnInit() {
    this.formControl = this.parent.form.get(this.controlName) as FormControl;
    this.errorPrefix = `errors.${this.controlName}.`;
  }

  enableEditMode() {
    this.data = this.formControl.value;
    setTimeout(() => {
      this.control.nativeElement.focus();
    }, 0);
  }

  onFocusOut() {
    if (this.formControl.invalid) {
      this.formControl.setValue(this.data);
      return;
    }

    this.focusOut.emit(true);
  }

}
