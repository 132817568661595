import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  active = false;
  isGlobalAdmin = false;
  isEmployee = false;
  currentClick = false;
  wasInside = false;

  constructor(private authService: AuthService, public matDialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.isGlobalAdmin = this.authService.isGlobalAdmin();
    this.isEmployee = this.authService.isEmployee();
  }

  toggle() {
    this.active = !this.active;
    this.currentClick = true;
  }

  isActive()
  {
    return this.active;
  }

  @HostListener('click')
  clickInside() {
    if (!this.active) {
      return;
    }

    this.active = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.active || this.currentClick) {
      this.currentClick = false;
      return;
    }

    if (!this.wasInside) {
      this.active = false;
    }
    this.wasInside = false;
  }
}
