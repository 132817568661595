<section class="header">
    <div class="row">
      <div class="col-md-12">
        <h1 style="float: left;">{{'pages.contact.detail.pageTitle' | translate: { name: form.controls.firstname.value + ' ' + form.controls.lastname.value } }}</h1>
      </div>
    </div>
  </section>
  
  
<form [formGroup]="form">
    <div class="row" *ngIf="contact !== undefined">
      <div class="col-md-12 col-xl-6">
  
        <div class="card">
          <div class="card-header">
            {{'pages.organisation.detail.infoTitle' | translate}}
          </div>
          <div class="card-body">
            <table class="inplace" style="word-break: break-all;">
              <tr>
                <td>{{ 'fields.organisation' | translate }}:</td>
                <td>
                  <div class="plain">
                    {{ contact.organisationName || ( "pages.contact.nonBusiness" | translate ) }}
                    <i class="fas fa-pencil-alt edit" (click)="openAssign()"></i>
                  </div>
                </td>
              </tr>
                <tr>
                  <td>{{ 'fields.firstname' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"firstname"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.lastname' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"lastname"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'fields.function' | translate }}:</td>
                  <td>
                    <app-inplace-input [controlName]='"function"' (focusOut)='saveForm()' [type]='"text"'>
                    </app-inplace-input>
                  </td>
                </tr>
              <tr>
                <td>{{ 'fields.email' | translate }}:</td>
                <td>
                  <app-inplace-input [controlName]='"email"' (focusOut)='saveForm()' [type]='"text"'></app-inplace-input>
                </td>
              </tr>
              <tr>
                <td>{{ 'fields.mobile' | translate }}:</td>
                <td>
                  <app-inplace-input [controlName]='"mobile"' (focusOut)='saveForm()' [type]='"text"'>
                  </app-inplace-input>
                </td>
              </tr>
              <tr>
                <td>{{ 'fields.telephone' | translate }}:</td>
                <td>
                  <app-inplace-input [controlName]='"telephone"' (focusOut)='saveForm()' [type]='"text"'>
                  </app-inplace-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  
  
  </form>