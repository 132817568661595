import { Component, OnInit, Input } from '@angular/core';
import { TokenCreationResponse } from '../../models/token-creation-request';
import { TokenEnabledResponse } from '../../models/token-enabled-response';
import { ProfileService } from '../../services/profile.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.scss']
})
export class TwoFaComponent implements OnInit {

  @Input() faEnabled: boolean;
  faStep = 0;
  tokenCreationResponse: TokenCreationResponse;
  tokenEnabledResponse: TokenEnabledResponse;
  codeIncorrect = false;

  authCode = new FormControl('', [ Validators.required ]);

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
  }

  enable() {
    this.profileService.generateToken().subscribe((responseObject) => {
      this.tokenCreationResponse = responseObject.data;
      this.faStep = 1;
    }, (error) => {});
  }

  disable() {
    this.profileService.disableToken().subscribe((responseObject) => {
      this.faEnabled = false;
    }, (error) => {});
  }

  cancel() {
    this.authCode.setValue('');
    this.faStep = 0;
  }

  entercode() {
    if(!this.authCode.valid) {
      this.codeIncorrect = true;
      return;
    }
    this.profileService.enableToken(this.authCode.value).subscribe((responseObject) => {
      this.tokenEnabledResponse = responseObject.data;
      console.log(this.tokenCreationResponse);

      this.codeIncorrect = false;
      this.faStep = 0;
      this.faEnabled = true;
    }, (error) => {
      this.codeIncorrect = true;
    });
  }
}
