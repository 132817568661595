<div class="card">
  <div class="card-header">
    {{'pages.resource.detail.inviteTitle' | translate}}
  </div>
  <div class="card-body">
    
    <div class="alert alert-danger" *ngIf="genericErrors !== undefined">
      <strong>{{'global.error' | translate}}:</strong><br />
      <ul>
        <li *ngFor="let error of genericErrors">
          <span *ngIf="error.code == 'invalidstate'">U heeft uw limiet bereikt.</span>
        </li>
      </ul>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-group">
        <label>{{'fields.email' | translate}}:</label>
        <input type="text" formControlName="email" class="form-control big" />
        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['email'].errors | objToKeys">
              {{ 'errors.email.' + error | translate }}
            </li>
          </ul>
        </div>
      </div>
      
      <div class="form-group">
        <button class="btn btn-primary" type="submit" [disabled]="form.invalid">{{'pages.resource.detail.inviteSubmit' | translate}}</button>&nbsp;&nbsp;<button type='button' class="btn btn-danger" (click)="close()">{{'global.close' | translate}}</button>
      </div>
    </form>
  </div>
</div>