<section class="header">
  <div class="row">
    <div class="col-md-12">
        <h1>{{'pages.auth.profile.pageTitle' | translate}}</h1>
    </div>
  </div>
</section>

<section class="content">
  
<div class="row" *ngIf="profile !== undefined">
  <div class="col-md-12 col-lg-6">

    <div class="card">
      <div class="card-header">
        {{'pages.auth.profile.profileTitle' | translate}}
      </div>
      <div class="card-body">
          <div class="alert alert-success" *ngIf="success">
            <strong>{{'global.success' | translate}}:</strong><br>{{'pages.auth.profile.profileSaved' | translate}}
          </div>
          <div class="alert alert-danger" *ngIf="error !== undefined && error !== null">
            <strong>{{'global.error' | translate}}:</strong><br>
            {{'global.invalidInput' | translate}}
          </div>
          <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="form-group">
              <label>{{'fields.email' | translate}}:</label>
              <input type="text" class="form-control" value="{{ profile.email }}" disabled />
            </div>
            <div class="form-group">
              <label>{{'fields.firstname' | translate}}:</label>
              <input type="text" formControlName="FirstName" class="form-control" value="{{ profile.firstName }}" />
              <div *ngIf="form.controls['FirstName'].invalid && (form.controls['FirstName'].dirty || form.controls['FirstName'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['firstName'].errors | objToKeys">
                    {{ 'errors.firstname.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.lastname' | translate}}:</label>
              <input type="text" formControlName="LastName" class="form-control" value="{{ profile.lastName }}" />
              <div *ngIf="form.controls['LastName'].invalid && (form.controls['LastName'].dirty || form.controls['LastName'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['LastName'].errors | objToKeys">
                    {{ 'errors.lastname.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'pages.auth.profile.newPassword' | translate}}:</label>
              <input type="password" formControlName="NewPassword" class="form-control" />
              <div *ngIf="form.controls['NewPassword'].invalid && (form.controls['NewPassword'].dirty || form.controls['NewPassword'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['NewPassword'].errors | objToKeys">
                    {{ 'errors.password.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'pages.auth.profile.newPasswordConf' | translate}}:</label>
              <input type="password" formControlName="NewPasswordConf" class="form-control" />
              <div *ngIf="form.controls['NewPasswordConf'].invalid && (form.controls['NewPasswordConf'].dirty || form.controls['NewPasswordConf'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['NewPasswordConf'].errors | objToKeys">
                    {{ 'errors.passwordConf.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>{{'fields.currentPassword' | translate}}:</label>
              <input type="password" formControlName="CurrentPassword" class="form-control" />

              <div *ngIf="form.controls['CurrentPassword'].invalid && (form.controls['CurrentPassword'].dirty || form.controls['CurrentPassword'].touched)" class="control-error">
                <ul>
                  <li *ngFor="let error of form.controls['CurrentPassword'].errors | objToKeys">
                    {{ 'errors.currentPassword.' + error | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-full-width" [disabled]="form.invalid">{{'pages.auth.profile.save' | translate}}</button>
            </div>
          </form>
      </div>
    </div>
  </div>
  <app-two-fa  class="col-md-12 col-lg-6" [faEnabled]="profile.twoFactorEnabled"></app-two-fa>
</div>

</section>