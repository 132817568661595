import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ListResponse } from 'src/app/models/list-response';
import { Employee } from '../models/employee';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { EmployeeList } from '../models/employee-list';

@Injectable({
  providedIn: 'root',
})
export class EmployeeHttpService {
  constructor(private httpClient: HttpClient) {}

  list(take: number, skip: number): Observable<ObjectResponse<EmployeeList>> {
    console.log(take);
    return this.httpClient.get<ObjectResponse<EmployeeList>>(`/api/v1/employee?take=${take}&skip=${skip}`);
  }

  invite(email: string): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/employee/invite`, { email });
  }

  enable(id: number): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/employee/${id}/enable`, { });
  }

  disable(id: number): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/employee/${id}/disable`, { });
  }

  deleteInvite(email: string): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/employee/delete-invite`, { email });
  }
}
