import { Address } from './address';

export class Organisation {
    id: number;
    name: string;
    customerCode: string;
    type: string;
    website: string;
    email: string;
    telephone: string;
    chamberOfCommerce: string;
    vatNumber: string;

    postalAddress: Address;
    invoiceAddress: Address;
    visitAddress: Address;
}
