import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListResponse } from 'src/app/models/list-response';
import { Invoice } from '../../models/invoice';
import { InvoiceHttpService} from '../../services/invoice-http.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateInvoiceDialogComponent } from '../../dialogs/create-invoice-dialog/create-invoice-dialog.component'

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  pageSize = 10;
  pageNum: number;

  @ViewChild('paging') paging;

  invoices: ListResponse <Invoice > ;

  constructor(private service: InvoiceHttpService,
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.pageNum = +params.page || 0;
      });
    }

  ngOnInit(): void {
    this.load();
  }

  create() {
    this.matDialog.open(CreateInvoiceDialogComponent, {
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.router.navigate([ 'invoice', result.id ]);
      }
    });
  }


  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.service.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.invoices = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
    }, (error) => {
      // We should error here
    });
  }

  load() {
    this.service.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.invoices = success;
    }, (error) => {});
  }

}

