import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeHttpService } from '../../services/employee-http.service';
import { Employee } from '../../models/employee';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Snackbar } from 'src/app/shared/helper/snackbar';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { InviteDialogComponent } from '../../dialogs/invite-dialog/invite-dialog.component';
import { EmployeeList } from '../../models/employee-list';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  pageSize = 10;
  pageNum: number;

  @ViewChild('paging') paging;

  employees: ObjectResponse < EmployeeList > ;

  constructor(private employeeService: EmployeeHttpService,
              public matDialog: MatDialog,
              private router: Router,
              public snackBar: Snackbar,
              private authService: AuthService,
              private route: ActivatedRoute) {
                this.route.queryParams.subscribe(params => {
                    this.pageNum = +params.page || 0;
                });
              }

  ngOnInit() {
    this.load();
  }

  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.employeeService.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.employees = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
    }, (error) => {
      // We should error here
    });
  }

  load() {
    this.employeeService.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.employees = success;
    }, (error) => {});
  }

  invite() {
    // Reload users after change
    this.matDialog.open(InviteDialogComponent, {
    }).afterClosed().subscribe(result => {
      if (result.finished) {
        this.load();
        this.snackBar.openSuccess('pages.employee.list.confirmations.inviteSnackbar');
      }
    });
  }

  disable(user: Employee) {
    if (+this.authService.getId() === user.id) {
      this.snackBar.openError('pages.employee.list.cannotDisableYourself')
      return;
    }

    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.employee.list.confirmations.disableTitle',
        message: 'pages.employee.list.confirmations.disableMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.employeeService.disable(user.id).subscribe((success) => {
          this.load();
          this.snackBar.openSuccess('pages.employee.list.confirmations.disableSnackbar');
        });
      }
    });
  }

  enable(user: Employee) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.employee.list.confirmations.enableTitle',
        message: 'pages.employee.list.confirmations.enableMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.employeeService.enable(user.id).subscribe((success) => {
          this.load();
          this.snackBar.openSuccess('pages.employee.list.confirmations.enableSnackbar');
        }, (error) => {
          if (error.error.errors.every(u => u.code === 'invalidstate')) {
            this.snackBar.openError('U heeft het gebruikers limiet bereikt.');
          }
        });
      }
    });
  }

  delete(user: Employee) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'pages.employee.list.confirmations.inviteTitle',
        message: 'pages.employee.list.confirmations.inviteMessage',
        data: {
          useremail: user.email
        }
      }
    }).afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.employeeService.deleteInvite(user.email).subscribe((success) => {
          this.load();
          this.snackBar.openSuccess('pages.employee.list.confirmations.deleteInviteSnackbar');
        }, (error) => {

        });
      }
    });
  }
}
